import React, { useEffect, useRef, useState } from "react";
import "../../styles/LifePage/LifeHeaderStyle.css";
import Slider from "react-slick";
import { images } from "../../utils/image";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTopMoments,
  getTopMomentsLife,
} from "../../store/action/TopMomentsAction";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  connectLife,
  disconnectLife,
  fetchLife,
  getConnectedLives,
  momentBulkUpload,
  searchLivesToConnect,
} from "../../store/action/LifeAction";
import Dropdown from "react-bootstrap/Dropdown";
import Spinner from "react-bootstrap/Spinner";
import { getFromSessionStorage } from "../../utils/storageHandler";
import {
  getAuthorizedEmails,
  getPermissionsToUpdate,
} from "../../store/action/MomentAction";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  XIcon,
} from "react-share";
import copy from "copy-to-clipboard";
import {
  addFollower,
  doesUserFollowLife,
  removeFollower,
} from "../../store/action/FollowerAction";
import Modal from "react-bootstrap/Modal";
import PopUpModal from "../PopUpModal";
import ReportLifeMoment from "../ReportLifeMoment";
import Zoom from "react-medium-image-zoom";
import Form from "react-bootstrap/Form";

const LifeHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentDate = new Date();

  const [mooflifeAdmin, setMooflifeAdmin] = useState(false);
  const [loadingDropdown, setLoadingDropdown] = useState(false);
  const [permissionGranted, setPermissionGrant] = useState(false);
  const [show, setShow] = useState(false);
  const [reportModalShow, setReportModalShow] = useState(false);
  const [showBulkUpload, setShowBulkUpload] = useState(false);
  const [showConnectedLifeModal, setShowConnectedLifeModal] = useState(false);
  const [shareBoxVisible, setShareBoxVisible] = useState(false);
  const [showTooltip, setTooltipShow] = useState(false);
  const [userLogin, setUserLogin] = useState("");
  const textRef = useRef();
  const [message, setMessage] = useState("");
  const [popupModalShow, setPopupModalShow] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedLifeId, setSelectedLifeId] = useState(null);
  const [searchSuggetion, setSearchSuggestion] = useState([]);
  const [words, setWords] = useState("");
  const [loadingConnectLife, setLoadingConnectLife] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const inputDropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(true);
  const [follow, setFollow] = useState(false);
  const [connectedLives, setConnectedLives] = useState([]);

  const Life = useSelector((state) => state.Life.Life);
  const [lifeTopMoments, setLifeTopMoments] = useState([]);
  const shareRef = useRef(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const descRef = useRef(null);

  useEffect(() => {
    // Check if the description needs truncation
    if (
      descRef.current &&
      descRef.current.scrollHeight > descRef.current.clientHeight
    ) {
      setIsTruncated(true);
    }
  }, [Life.life_description]);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (shareRef.current && !shareRef.current.contains(event.target)) {
        setShareBoxVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    checkAuthorizedEmails();
  }, []);

  useEffect(() => {
    const userDetails = getFromSessionStorage("userDetails", true);
    setUserLogin(userDetails);
    if (userDetails) {
      dispatch(
        doesUserFollowLife({ userId: userDetails.id, lifeId: Life.life_id })
      )
        .then((res) => {
          setFollow(res);
        })
        .catch((error) => {
          console.error("Error in get follow state", error);
        });
    }
    handleConnectedLives();
  }, [Life.life_id]);

  useEffect(() => {
    const momentDate = moment(currentDate).format("YYYY-MM-DD");
    const lifeId = Life._id;

    dispatch(
      getTopMomentsLife(
        {
          date: momentDate,
          life: lifeId,
        },
        1
      )
    ).then((res) => {
      
      setLifeTopMoments(res);
    });
  }, [Life._id]);

  const isInfinite = lifeTopMoments.length <= 1;

  const innerSettings = {
    dots: false,
    infinite: isInfinite ? false : true,
    slidesToShow: 2,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 8000,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],

    afterChange: (currentSlide) => (
      <button onClick={() => console.log("after change", currentSlide)}>
        <i
          className="bi bi-chevron-double-right"
          style={{ color: "white", fontSize: "60px" }}
        ></i>
      </button>
    ),
  };

  const slideOne = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 8000,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const checkAuthorizedEmails = async () => {
    const userDetails = getFromSessionStorage("userDetails", true);
    if (userDetails && userDetails.email) {
      dispatch(
        await getAuthorizedEmails({ userEmail: userDetails.email })
      ).then((res) => {
        if (res && res.success && res.data) {
          setMooflifeAdmin(res.data.result);
        }
      });
    }
  };

  const handleConnectedLives = async () => {
    if (Life && Life._id) {
      const connectedLivesList = await dispatch(
        getConnectedLives({ lifeId: Life._id })
      );
      if (connectedLivesList && connectedLivesList.success) {
        setConnectedLives(connectedLivesList.data);
      }
    }
  };

  const handleDropDown = async (isOpen) => {
    setLoadingDropdown(true);
    if (isOpen && Life) {
      const userData = getFromSessionStorage("userDetails", true);
      dispatch(
        await getPermissionsToUpdate({
          lifeId: Life.life_id,
          userEmail: userData?.email,
        })
      ).then((permission) => {
        setPermissionGrant(permission);
        setLoadingDropdown(false);
      });
    }
  };

  const updateLife = () => {
    if (Life && Life.life_id) {
      const lifeInState = {
        lifeId: Life.life_id,
      };
      navigate("/update-life", { state: lifeInState });
    }
  };

  const showReportModal = () => {
    setReportModalShow(true);
  };

  const handleBulkUploadShow = () => {
    setShowBulkUpload(true);
    setShowBulkUpload(true);
  };

  const handleConnections = async () => {
    setShowConnectedLifeModal(true);
  };

  const currentUrl =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "");

  const handleShareClick = () => {
    setShareBoxVisible(true);
  };

  //Function to add text to clipboard
  const copyToClipboard = () => {
    let copyText = textRef.current.value;
    let isCopy = copy(copyText);
    if (isCopy) {
      setTooltipShow(true);

      setTimeout(() => {
        setTooltipShow(false);
      }, 3000);
    }
  };

  const handleBulkUploadClose = () => {
    setSelectedFile(null);
    setShowBulkUpload(false);
    setMessage("");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleBulkUploadFile = async () => {
    setLoading(true);
    if (selectedFile && Life && Life._id && Life.life_name) {
      const bulkUploadRes = await dispatch(
        momentBulkUpload(selectedFile, Life._id, Life.life_name)
      );
      if (bulkUploadRes && bulkUploadRes.success) {
        //upload completed!
        setLoading(false);
        setMessage("Upload Completed!");
        window.location.reload();
      } else {
        let error = null;
        if (bulkUploadRes.error) {
          error = bulkUploadRes.error;
        }
        setMessage(error ? `Error: ${error}` : "Moment bulk upload failed!");
        setTimeout(() => {
          setMessage("");
        }, 3000);
      }
    }
  };

  const connectedLifeModalClose = () => {
    setShowConnectedLifeModal(false);
    setSelectedLifeId("");
  };

  const handleLifeModalShow = () => {
    setSearchSuggestion([]);
    setWords("");
    setSelectedLifeId(null);
  };

  const handleDisconnectLife = async (lifeId) => {
    setLoadingConnectLife(true);
    const disconnectLifeRes = await dispatch(
      disconnectLife({ sourceLifeId: lifeId, targetLifeId: Life._id })
    );
    if (disconnectLifeRes && disconnectLifeRes.success) {
      const message = disconnectLifeRes.data.message
        ? disconnectLifeRes.data.message
        : "Successful! Life disconnection Completed.";
      handleConnectedLives();
      setLoadingConnectLife(false);
      setMessage(message);
      setLoadingConnectLife(false);
      setShowConnectedLifeModal(false);
      setPopupModalShow(true);

      setTimeout(() => {
        setPopupModalShow(false);
        window.location.reload();
      }, 3000);
    } else {
      let error = null;
      let actionModal = false;
      if (disconnectLifeRes.error) {
        error = disconnectLifeRes.error;
      }

      if (disconnectLifeRes.action) {
        actionModal = true;
        setActionModal(true);
      }

      const errorMsg = error
        ? error
        : "We ran into an error ! Please try again later.";
      setLoadingConnectLife(false);
      setMessage(errorMsg);
      setShowConnectedLifeModal(false);
      setPopupModalShow(true);
    }

    setWords("");
  };

  const handleConnectLife = async () => {
    setLoadingConnectLife(true);
    if (selectedLifeId && selectedLifeId !== "" && Life) {
      const lifeConnectRes = await dispatch(
        connectLife({ sourceLife: selectedLifeId, targetLife: Life.life_id })
      );
      if (lifeConnectRes && lifeConnectRes.success) {
        const message = lifeConnectRes.data.message
          ? lifeConnectRes.data.message
          : "Successful! Life Connection Completed.";
        handleConnectedLives();
        setMessage(message);
        setLoadingConnectLife(false);
        setShowConnectedLifeModal(false);
        setPopupModalShow(true);

        setTimeout(() => {
          setPopupModalShow(false);
          window.location.reload();
        }, 3000);
      } else {
        let error = null;
        let actionModal = false;
        if (lifeConnectRes.error) {
          error = lifeConnectRes.error;
        }

        if (lifeConnectRes.action) {
          actionModal = true;
          setActionModal(true);
        }

        const errorMsg = error
          ? error
          : "We ran into an error ! Please try again later.";
        setMessage(errorMsg);
        setLoadingConnectLife(false);
        setShowConnectedLifeModal(false);
        setPopupModalShow(true);
      }

      setSearchSuggestion([]);
      setWords("");
      setSelectedLifeId(null);
    }
  };

  const handleSearchSuggestion = async (input) => {
    if (input && Life && Life._id) {
      const searchresults = await dispatch(
        searchLivesToConnect({ input: input, sourceLifeId: Life._id })
      );
      if (searchresults && searchresults.success) {
        setSearchSuggestion(searchresults.data);
      }
    }
  };

  const handleSelectedLife = (lifeId) => {
    setSelectedLifeId((prevState) => (prevState === lifeId ? null : lifeId));
  };

  const handleFollowClick = () => {
    setLoading(true);
    if (follow) {
      //unfollow life
      dispatch(removeFollower({ user_id: userLogin.id, life_id: Life.life_id }))
        .then((res) => {
          setFollow(false);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error in remove follower", error);
          setLoading(false);
        });
    } else {
      // follow life
      dispatch(addFollower({ user_id: userLogin.id, life_id: Life.life_id }))
        .then((res) => {
          setFollow(true);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error in add follower", error);
          setLoading(false);
        });
    }
  };

  const [packageColor, setPackageColor] = useState('');
  
  useEffect(() => {
    if (Life.current_subscription && Life.current_subscription.slice(3) === "Elite") {
      setPackageColor("#FFBF00");
    } else if (Life.current_subscription && Life.current_subscription.slice(3) === "Premium") {
      setPackageColor("#FF5900");
    } else if (Life.current_subscription && Life.current_subscription.slice(3) === "Plus") {
      setPackageColor("#3787FF");
    } else if (Life.current_subscription === "Free") {
      setPackageColor("#484848");
    } else {
      setPackageColor("#FFBF00");
    }
  }, [Life.current_subscription]);
  
  
  return (
    <>
      <div>
        {userLogin && (
          <ReportLifeMoment
            title="Report Life"
            subtitle="Why do you want to report this life?"
            lifeMomentId={Life._id}
            show={show}
            setShow={(bool) => setShow(bool)}
          ></ReportLifeMoment>
        )}

        {userLogin && (
          <ReportLifeMoment
            title="Report Life"
            subtitle="Why do you want to report this life?"
            lifeMomentId={Life._id}
            show={reportModalShow}
            setShow={(bool) => setReportModalShow(bool)}
          ></ReportLifeMoment>
        )}

        <PopUpModal
          title={message}
          show={popupModalShow}
          setPopupModalShow={(bool) => setPopupModalShow(bool)}
          setActionModal={(bool) => setActionModal(bool)}
          actionModal={actionModal}
        ></PopUpModal>

        {showBulkUpload && (
          <Modal
            show={handleBulkUploadShow}
            onHide={handleBulkUploadClose}
            backdrop="static"
            keyboard={false}
            centered
            className="bulk-upload-modal"
          >
            <Modal.Header className="modal-topic" closeButton>
              <div>Upload File</div>
            </Modal.Header>
            <Modal.Body className="modal-body">
              <input
                id="fileSelect"
                type="file"
                accept=".xlsx"
                onChange={handleFileChange}
              />

              {message && <div className="error-message">{message}</div>}

              <div className="d-flex gap-2 justify-content-center">
                <button
                  className="yellow-btn"
                  onClick={() => handleBulkUploadFile()}
                >
                  {loading ? (
                    <Spinner
                      animation="border"
                      variant="light"
                      style={{
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
                <button
                  className="yellow-btn"
                  onClick={handleBulkUploadClose}
                  disabled={loading}
                >
                  Cancel
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        )}

        {showConnectedLifeModal && (
          <Modal
            className="link-moment-modal connected-life-modal"
            show={showConnectedLifeModal}
            onHide={connectedLifeModalClose}
            onShow={handleLifeModalShow}
            centered
            size="xl"
            backdrop="static"
          >
            <Modal.Header className="title">
              Connect Life to My Life
            </Modal.Header>
            <div className="d-flex gap-5">
              <div className="modal-body">
                <div className="title">Connected Lives</div>

                {connectedLives.map((life, index) => (
                  <div className="card">
                    <div
                      key={index}
                      className="d-flex gap-3 align-items-center justify-content-between"
                    >
                      <div className="life-set">
                        <div className="d-flex gap-3 align-items-center">
                          <Zoom>
                            <img
                              src={life.life_logo}
                              className="create-dropdown-img"
                            ></img>
                          </Zoom>
                          <div>
                            <div className="life-name">{life.life_name}</div>
                            <div className="life-tag">{life.life_title}</div>
                          </div>
                        </div>
                        <div></div>
                      </div>
                      <button
                        className="yellow-btn"
                        onClick={() => handleDisconnectLife(life._id)}
                        disabled={loadingConnectLife}
                      >
                        {loadingConnectLife ? (
                          <Spinner
                            animation="border"
                            variant="light"
                            style={{
                              width: "15px",
                              height: "15px",
                            }}
                          />
                        ) : (
                          "Disconnect"
                        )}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="vl"></div>
              <div className="modal-body">
                <div className="title">Connect a New Life</div>

                <div>
                  {showSearch && (
                    <Form>
                      <div className="select-form">
                        <div className="search-menu-btn" ref={inputDropdownRef}>
                          <div>
                            <input
                              id="searchText"
                              className={`search ${
                                showDropdown && !words.length > 0
                                  ? "expanded"
                                  : "search"
                              }`}
                              type="text"
                              placeholder="Search Lives"
                              value={words}
                              onChange={(e) => {
                                setWords(e.target.value);
                                handleSearchSuggestion(e.target.value);
                              }}
                              autocomplete="one-time-code"
                            />
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </div>

                {showDropdown === true &&
                  searchSuggetion &&
                  searchSuggetion.length > 0 && (
                    <div className="suggest-lives">
                      {searchSuggetion.map((searchLife, index) => (
                        <div
                          className="card"
                          key={index}
                          onClick={() => handleSelectedLife(searchLife.life_id)}
                          style={{
                            border:
                              selectedLifeId === searchLife.life_id
                                ? "2px solid #FFC000"
                                : "",
                          }}
                        >
                          <div className="life-set">
                            <div className="d-flex gap-3 align-items-center">
                              <Zoom>
                                <img
                                  src={searchLife.life_logo}
                                  className="create-dropdown-img"
                                ></img>
                              </Zoom>
                              <div>
                                <div className="life-name">
                                  {searchLife.life_name}
                                </div>
                                <div className="life-tag">
                                  {searchLife.life_title}
                                </div>
                              </div>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                <div className="btn-set">
                  <button
                    className="yellow-btn"
                    onClick={() => handleConnectLife()}
                    disabled={loadingConnectLife || !selectedLifeId}
                  >
                    {loadingConnectLife ? (
                      <Spinner
                        animation="border"
                        variant="light"
                        style={{
                          width: "15px",
                          height: "15px",
                        }}
                      />
                    ) : (
                      "Connect"
                    )}
                  </button>
                  <button
                    className="yellow-btn"
                    onClick={connectedLifeModalClose}
                    disabled={loadingConnectLife}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
      <div className="new-life-header">
        <div className="life-top-moment-main">
          <div className="content">
            <img src={Life.cover_image} alt="image" />
            <div className="title-row">
              <div className="title">
                <img src={Life.life_logo} className="life-logo" alt="image" />
              </div>
            </div>
          </div>

          <div className="inner-slide">
            {lifeTopMoments.length > 1 && (
              <div className="top-moments-content">
                <div className="title-1">On this day</div>
                <Slider {...innerSettings}>
                  {lifeTopMoments.map((topMoment, index) =>
                    topMoment.images?.length > 0 &&
                    topMoment.images[0]?.media_type === "image" ? (
                      <div key={index}>
                        <Link
                          to={`/${
                            topMoment?.life?.verified_name ||
                            topMoment?.life?.life_id
                          }/${topMoment._id}`}
                          target="_blank"
                        >
                          <img
                            className="small-slider-image"
                            src={topMoment.images[0].url}
                            alt="topmomentImage"
                          />
                        </Link>
                        <div className="title-row">
                          <div className="date">
                            <Link
                              to={`/${
                                topMoment.life.verified_name
                                  ? topMoment.life.verified_name
                                  : topMoment.life.life_id
                              }`}
                            >
                              {topMoment.life?.life_name.length > 10
                                ? topMoment.life.life_name.slice(0, 10) + "..."
                                : topMoment.life.life_name}
                              &nbsp;: {topMoment.moment_date}
                            </Link>
                          </div>

                          <div className="title">
                            <Link
                              to={`/${
                                topMoment?.life?.verified_name ||
                                topMoment?.life?.life_id
                              }/${topMoment._id}`}
                              target="_blank"
                            >
                              {topMoment.moment_title.length > 45
                                ? topMoment.moment_title.slice(0, 45) + "..."
                                : topMoment.moment_title}
                            </Link>
                          </div>
                        </div>
                      </div>
                    ) : null
                  )}
                </Slider>
              </div>
            )}

            {lifeTopMoments.length === 1 && (
              <div className="top-moments-content slide-one">
                <div className="title-1">On this day</div>
                <Slider {...slideOne}>
                  {lifeTopMoments.map((topMoment, index) =>
                    topMoment.images?.length > 0 &&
                    topMoment.images[0]?.media_type === "image" ? (
                      <div key={index}>
                        <Link
                          to={`/${
                            topMoment?.life?.verified_name ||
                            topMoment?.life?.life_id
                          }/${topMoment._id}`}
                          target="_blank"
                        >
                          <img
                            className="small-slider-image"
                            src={topMoment.images[0].url}
                            alt="topmomentImage"
                          />
                        </Link>
                        <div className="title-row">
                          <div className="date">
                            <Link
                              to={`/${
                                topMoment.life.verified_name
                                  ? topMoment.life.verified_name
                                  : topMoment.life.life_id
                              }`}
                              target="_blank"
                            >
                              {topMoment.life?.life_name.length > 10
                                ? topMoment.life.life_name.slice(0, 10) + "..."
                                : topMoment.life.life_name}
                              &nbsp;: {topMoment.moment_date}
                            </Link>
                          </div>
                          <div className="title">
                            <Link
                              to={`/${
                                topMoment?.life?.verified_name ||
                                topMoment?.life?.life_id
                              }/${topMoment._id}`}
                              target="_blank"
                            >
                              {topMoment.moment_title.length > 45
                                ? topMoment.moment_title.slice(0, 45) + "..."
                                : topMoment.moment_title}
                            </Link>
                          </div>
                        </div>
                      </div>
                    ) : null
                  )}
                </Slider>
              </div>
            )}
            <Link className="life-link" to={`${Life.web_link?.startsWith('https://') ? Life.web_link : "https://" + Life.web_link}`} target="_blank">
              {Life.web_link?.length > 30
                ? Life.web_link.slice(0, 30) + "..."
                : Life.web_link}
              <span>
                {" "}
                <i class="bi bi-globe-asia-australia"></i>
              </span>
            </Link>
          </div>
        </div>

        <div className="life-details">
          <div className="word">
            <div>
              <div className="life-name">
                {Life.life_name?.length > 40
                  ? Life.life_name.slice(0, 40) + "..."
                  : Life.life_name}
              </div>
              <div className="life-title">
                {" "}
                {Life.life_title?.length > 50
                  ? Life.life_title.slice(0, 50) + "..."
                  : Life.life_title}
              </div>
            </div>

            <div>
              <div className="icons">
                <div>
                  <div className="moments-row">
                    <h6 className="moments-number">{Life.no_of_moments}</h6>
                    <h6 className="moment-tag">Moments</h6>
                  </div>
                </div>

                <>
                  <div className="icons share-icon">
                    <div class="share-feed">
                      <a
                        class="share-btn"
                        onClick={handleShareClick}
                        ref={shareRef}
                      >
                        <i
                          class="bi bi-share-fill"
                          style={{ color: "#9E9797" }}
                        ></i>
                        {showTooltip === true && (
                          <div className="copy-message">Link copied</div>
                        )}
                        {shareBoxVisible && (
                          <div
                            class="share-box"
                            style={{
                              left: userLogin ? "-15px" : "-135px",
                            }}
                          >
                            <div class="share-icon">
                              <label>Telegram</label>
                              <TelegramShareButton
                                url={`${currentUrl}/${
                                  Life.verified_name || Life.life_id
                                }`}
                                title={Life.life_name}
                              >
                                <TelegramIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </TelegramShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Linkedin</label>
                              <LinkedinShareButton
                                url={`${currentUrl}/${
                                  Life.verified_name || Life.life_id
                                }`}
                                title={Life.life_name}
                              >
                                <LinkedinIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </LinkedinShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Whatsapp</label>
                              <WhatsappShareButton
                                url={`${currentUrl}/${
                                  Life.verified_name || Life.life_id
                                }`}
                                title={Life.life_name}
                                separator=":: "
                              >
                                <WhatsappIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </WhatsappShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Facebook</label>
                              <FacebookShareButton
                                url={`${currentUrl}/${
                                  Life.verified_name || Life.life_id
                                }`}
                              >
                                <FacebookIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </FacebookShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Mask</label>
                              <TwitterShareButton
                                url={`${currentUrl}/${
                                  Life.verified_name || Life.life_id
                                }`}
                                title={Life.life_name}
                              >
                                <XIcon size={32} round className="icon" />
                              </TwitterShareButton>
                            </div>
                            <div class="share-icon icon-copy">
                              <div class="hover-text">
                                {/* <!-- Copy Text --> */}
                                <div className="copy-text d-flex">
                                  <input
                                    value={`${currentUrl}/${
                                      Life.verified_name || Life.life_id
                                    }`}
                                    disabled
                                    type="text"
                                    ref={textRef}
                                  />
                                </div>
                              </div>
                              <img
                                src={images.CopyIcon}
                                onClick={copyToClipboard}
                                className="icon"
                              ></img>
                            </div>
                          </div>
                        )}
                      </a>
                    </div>
                  </div>
                </>

                {userLogin && (
                  <div className="follow-row">
                    {userLogin && (
                      <>
                        {!follow && (
                          <Dropdown style={{ marginTop: "-5px" }}>
                            <Dropdown.Toggle id="dropdown-basic">
                              <button
                                className="follow-btn"
                                onClick={handleFollowClick}
                              >
                                {loading ? (
                                  <Spinner
                                    animation="border"
                                    variant="dark"
                                    style={{
                                      width: "15px",
                                      height: "15px",
                                    }}
                                  />
                                ) : (
                                  "Follow"
                                )}
                              </button>
                            </Dropdown.Toggle>
                          </Dropdown>
                        )}

                        {follow && (
                          <Dropdown className="follow-dropdown">
                            <Dropdown.Toggle id="dropdown-basic">
                              <div className="follow-btn">
                                {loading ? (
                                  <Spinner
                                    animation="border"
                                    variant="dark"
                                    style={{
                                      width: "15px",
                                      height: "15px",
                                    }}
                                  />
                                ) : (
                                  <div>
                                    <span>
                                      <i
                                        class="bi bi-check-lg"
                                        style={{ marginRight: "10px" }}
                                      ></i>
                                    </span>
                                    Following
                                  </div>
                                )}
                              </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                href="#"
                                onClick={handleFollowClick}
                              >
                                Unfollow
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </>
                    )}
                  </div>
                )}

                {userLogin && (
                  <div className="menu-row">
                    {userLogin && (
                      <Dropdown
                        onToggle={(isOpen) => handleDropDown(isOpen)}
                        className="more-dropdown"
                        style={{ marginTop: "-5px" }}
                      >
                        <Dropdown.Toggle id="dropdown-basic">
                          <div className="more-btn">
                            <i class="bi bi-three-dots"></i>
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-menu">
                          <Dropdown.Item
                            href="#"
                            onClick={() => showReportModal()}
                          >
                            {loadingDropdown ? (
                              <Spinner
                                animation="border"
                                variant="dark"
                                style={{
                                  width: "15px",
                                  height: "15px",
                                }}
                              />
                            ) : (
                              "Report Life"
                            )}
                          </Dropdown.Item>

                          {mooflifeAdmin && (
                            <Dropdown.Item
                              href="#"
                              onClick={handleBulkUploadShow}
                            >
                              Bulk Upload
                            </Dropdown.Item>
                          )}
                          {(permissionGranted || mooflifeAdmin) && (
                            <Dropdown.Item onClick={handleConnections}>
                              Connect Lives
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                        <Dropdown.Menu>
                          {permissionGranted && (
                            <Dropdown.Item onClick={() => updateLife()}>
                              {loadingDropdown ? (
                                <Spinner
                                  animation="border"
                                  variant="dark"
                                  style={{
                                    width: "15px",
                                    height: "15px",
                                  }}
                                />
                              ) : (
                                "Update Life"
                              )}
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr className="line" style={{ border: `5px solid ${packageColor}` }} />
          <div>
            <div
              className={`life-desc ${isExpanded ? "expanded" : "truncated"}`}
              ref={descRef}
            >
              <>{Life.life_description}</>
            </div>
            {isTruncated && !isExpanded && (
              <div onClick={handleToggle} className="read-more-button">
                Read More ...
              </div>
            )}
            {isExpanded && (
              <div onClick={handleToggle} className="read-more-button">
                Read Less ...
              </div>
            )}
            <div className="life-tag">{Life.life_tag}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LifeHeader;
