import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import "../../src/styles/RegisterStyle.css";
import "../../src/styles/CommonStyle.css";
import { images } from "../utils/image";
import {
  registerNewUser,
  setUserDetails,
  sendOTP,
  isEmailAvailable,
  googleAuth
} from "../store/action/userInteractionsAction";
import { useNavigate } from "react-router-dom";
import { getFromSessionStorage, setIntoSessionStorage, setIntoLocalStorage } from "../utils/storageHandler";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import MomentJS from "moment";
const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const RegisterPage = () => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const isGoogleAuth = false;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [newUser, setNewUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    country: "",
    interests: [],
    selected_countries: [],
    otp: false,
    terms_and_condition: false,
  });

  //Change the form when user login from Google
  useEffect(() => {
    if (isGoogleAuth === true) {
      setStep(step + 1);
    }
  }, [isGoogleAuth]);

  useEffect(() => {
    const accessToken = getFromSessionStorage("accessToken");
    if (accessToken) {
      navigate("/");
    }
  }, []);

  const onValueChange = (evt) => {
    const { id, value } = evt.target;
    if (id === "confirmPassword") {
      setConfirmPassword(value);
    } else if (id === "email") {
      setErrorMessage("");
      setNewUser((prevValues) => ({
        ...prevValues,
        [id]: value.toLowerCase(),
      }));
    } else {
      setNewUser((prevValues) => ({
        ...prevValues,
        [id]: value,
      }));
    }
  };

  const handlePasswordChange = (e) => {
    setShowMessage(true);
  };

  //Navigate to next page and previous page
  const nextStep = async () => {
    if (step === 1) {
      if (!emailPattern.test(newUser.email)) {
        setErrorMessage("Type valid email");
      } else {
        dispatch(
          await isEmailAvailable({
            email: newUser.email,
          })
        )
          .then((res) => {
            if (res.available) {
              setErrorMessage("");
              setStep(step + 1);
            } else {
              setErrorMessage("Already a user registered with this email");
            }
          })
          .catch((error) => {
            console.error("Error in reset password:", error);
          });
      }
    }
    if (step === 2) {
      dispatch(setUserDetails(newUser));
      dispatch(sendOTP({ email: newUser.email }));
      navigate("/email-verification");
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  //Toggle Eye for password visible
  const togglePassword = () => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const validateValues = () => {
    const { first_name, last_name, email, password, country } = newUser;

    if (
      first_name !== "" &&
      last_name !== "" &&
      email !== "" &&
      password !== "" &&
      country !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const submitDetails = () => {
    const validated = validateValues();
    if (validated) {
      dispatch(registerNewUser(newUser));
    }
  };

  //Password validation
  const validatePassword = () => {
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /[0-9]/g;
    const specialCharRegex = /[-!@#?<>()&/\\'"]/g; // Add your special characters here
    const noSpaceOrRestrictedCharRegex = /^[^\s]+$/; // Regex to check for spaces and restricted characters
    const minLength = newUser.password.length >= 8;

    return {
      lowerUppercase:
        newUser.password.match(lowercaseRegex) &&
        newUser.password.match(uppercaseRegex),
      number: newUser.password.match(numberRegex),
      specialChar: newUser.password.match(specialCharRegex),
      noSpaceOrRestrictedChar: noSpaceOrRestrictedCharRegex.test(
        newUser.password
      ),
      length: minLength,
    };
  };

  const validationResults = validatePassword();

  const onSuccess = (res) => {
    dispatch(googleAuth({ credential: res.credential }))
      .then((res) => {
        if (res && res.success && res.data && res.data.token) {
          const tokenExpireAt = MomentJS().add(10, "hours");
         
            setIntoSessionStorage("accessToken", res.data.token);
            setIntoSessionStorage("userDetails", res.data.userDetails, true);
            setIntoSessionStorage("tokenExpireTime", tokenExpireAt);
            setIntoSessionStorage("interest", res.data?.interest, true);
            setIntoSessionStorage("selected_countries", res.data?.selected_countries, true);
            setIntoSessionStorage("register_type", res.data?.register_type);
          
          navigate("/");
        }
        if (res && res.success && res.data && res.data.newUser) {
          dispatch(setUserDetails(res.data.newUser))
            .then(() => {
              navigate(`/terms-and-conditions`, {
                state: { type: "google", rememberMe: false },
              });
            })
            .catch((error) => {
              console.error("Error in setUserDetails:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error in login:", error);
      });
  };

  const onFailure = (res) => {
    console.log("Google LOGIN FAILED !");
  };

  return (
    <div>
      <Container className="register-main">
        <Row>
          <Col sm={5}>
            <img className="register-img" src={images.registerBg}></img>
          </Col>
          <Col sm={7}>
            <Card className="text-center card">
              <Card.Body>
                {step === 1 && <p className="step">Step 1 of 2</p>}
                {step === 2 && isGoogleAuth === false && (
                  <p className="step">Step 2 of 2</p>
                )}
                <div className="register-container">
                  <Link className="register-logo" to="/">
                    <img className="logo" src={images.moofLogo}></img>
                  </Link>
                  <section>
                    {step === 1 && (
                      <div className="register-form">
                        <p className="title-1">
                          Sign Up for a new MoofLife Account
                        </p>

                        <input
                          className="form-control"
                          id="first_name"
                          name="name"
                          type="text"
                          placeholder="First Name"
                          value={newUser.first_name}
                          onChange={onValueChange}
                          maxLength="25"
                        />
                        <input
                          className="form-control"
                          type="text"
                          id="last_name"
                          name="lastName"
                          placeholder="Last Name"
                          value={newUser.last_name}
                          onChange={onValueChange}
                          maxLength="25"
                        />
                        <div>
                          <input
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={newUser.email}
                            onChange={onValueChange}
                          />
                        </div>
                        {errorMessage && (
                          <small>
                            <div className="error-message">
                              <span>
                                <i class="bi bi-exclamation-circle"></i>
                              </span>
                              {errorMessage}
                            </div>
                          </small>
                        )}

                        <button
                          type="button"
                          className="btn-register"
                          onClick={nextStep}
                          disabled={
                            newUser.first_name === "" ||
                            newUser.last_name === "" ||
                            newUser.email === ""
                          }
                        >
                          Next
                        </button>
                        <div className="or-word">
                      Or
                    </div>
                        <div
                          className="google-login"
                        >
                          <div className="google-login-title">
                            Sign in with your Google account
                          </div>
                          <GoogleOAuthProvider
                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                          >
                            <GoogleLogin
                              className="custom-google-login"
                              buttonText="Login"
                              onSuccess={(res) => onSuccess(res)}
                              onFailure={onFailure}
                              cookiePolicy={"single_host_origin"}
                              isSignedIn={true}
                            />
                          </GoogleOAuthProvider>
                        </div>
                      </div>
                    )}

                    {step === 2 && (
                      <div className="register-form">
                        <p className="title-1">
                          {isGoogleAuth === true
                            ? "Select your country"
                            : "Sign Up for a new MoofLife Account"}
                        </p>
                        {isGoogleAuth === false && (
                          <div>
                            <div className="password-input-container">
                              <input
                                className="form-control"
                                type={passwordType}
                                id="password"
                                placeholder="Password"
                                maxLength={25}
                                value={newUser.password}
                                onChange={onValueChange}
                                onChangeCapture={handlePasswordChange}
                              />
                              <button
                                type="button"
                                className="toggle-password"
                                onClick={togglePassword}
                              >
                                {passwordType === "password" ? (
                                    <img src={images.eyeSlash} width="20px"></img>
                                    ) : (
                                    <img src={images.eye} width="20px"></img>
                                )}
                              </button>
                            </div>
                            {newUser.password.length > 1 &&
                              validationResults.noSpaceOrRestrictedChar ===
                                false && (
                                <div className="error-message">
                                  <span>
                                    <i class="bi bi-exclamation-circle"></i>
                                  </span>
                                  Do not include spaces
                                </div>
                              )}
                            <div className="password-input-container">
                              <input
                                className="form-control"
                                type={confirmPasswordType}
                                id="confirmPassword"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={onValueChange}
                                maxLength={25}
                              />
                              <button
                                type="button"
                                className="toggle-password"
                                onClick={toggleConfirmPassword}
                              >
                                {confirmPasswordType === "password" ? (
                                    <img src={images.eyeSlash} width="20px"></img>
                                    ) : (
                                    <img src={images.eye} width="20px"></img>
                                )}
                              </button>
                            </div>
                            {confirmPassword !== newUser.password && (
                              <small>
                                <div className="error-message">
                                  <span>
                                    <i class="bi bi-exclamation-circle"></i>
                                  </span>
                                  Password does not match
                                </div>
                              </small>
                            )}
                          </div>
                        )}

                        <select
                          className="form-control"
                          id="country"
                          name="country"
                          value={newUser.country}
                          onChange={onValueChange}
                          placeholder="select"
                        >
                          <option value="" disabled="disabled">
                            Country
                          </option>
                          <option value="Afghanistan">Afghanistan</option>
                          <option value="Aland Islands">Aland Islands</option>
                          <option value="Albania">Albania</option>
                          <option value="Algeria">Algeria</option>
                          <option value="American Samoa">American Samoa</option>
                          <option value="Andorra">Andorra</option>
                          <option value="Angola">Angola</option>
                          <option value="Anguilla">Anguilla</option>
                          <option value="Antarctica">Antarctica</option>
                          <option value="Antigua and Barbuda">
                            Antigua and Barbuda
                          </option>
                          <option value="Argentina">Argentina</option>
                          <option value="Armenia">Armenia</option>
                          <option value="Aruba">Aruba</option>
                          <option value="Australia">Australia</option>
                          <option value="Austria">Austria</option>
                          <option value="Azerbaijan">Azerbaijan</option>
                          <option value="Bahamas">Bahamas</option>
                          <option value="Bahrain">Bahrain</option>
                          <option value="Bangladesh">Bangladesh</option>
                          <option value="Barbados">Barbados</option>
                          <option value="Belarus">Belarus</option>
                          <option value="Belgium">Belgium</option>
                          <option value="Belize">Belize</option>
                          <option value="Benin">Benin</option>
                          <option value="Bermuda">Bermuda</option>
                          <option value="Bhutan">Bhutan</option>
                          <option value="Bolivia">Bolivia</option>
                          <option value="Bonaire, Sint Eustatius and Saba">
                            Bonaire, Sint Eustatius and Saba
                          </option>
                          <option value="Bosnia and Herzegovina">
                            Bosnia and Herzegovina
                          </option>
                          <option value="Botswana">Botswana</option>
                          <option value="Bouvet Island">Bouvet Island</option>
                          <option value="Brazil">Brazil</option>
                          <option value="British Indian Ocean Territory">
                            British Indian Ocean Territory
                          </option>
                          <option value="Brunei Darussalam">
                            Brunei Darussalam
                          </option>
                          <option value="Bulgaria">Bulgaria</option>
                          <option value="Burkina Faso">Burkina Faso</option>
                          <option value="Burundi">Burundi</option>
                          <option value="Cambodia">Cambodia</option>
                          <option value="Cameroon">Cameroon</option>
                          <option value="Canada">Canada</option>
                          <option value="Cape Verde">Cape Verde</option>
                          <option value="Cayman Islands">Cayman Islands</option>
                          <option value="Central African Republic">
                            Central African Republic
                          </option>
                          <option value="Chad">Chad</option>
                          <option value="Chile">Chile</option>
                          <option value="China">China</option>
                          <option value="Christmas Island">
                            Christmas Island
                          </option>
                          <option value="Cocos (Keeling) Islands">
                            Cocos (Keeling) Islands
                          </option>
                          <option value="Colombia">Colombia</option>
                          <option value="Comoros">Comoros</option>
                          <option value="Congo">Congo</option>
                          <option value="Congo, Democratic Republic of the Congo">
                            Congo, Democratic Republic of the Congo
                          </option>
                          <option value="Cook Islands">Cook Islands</option>
                          <option value="Costa Rica">Costa Rica</option>
                          <option value="Cote D'Ivoire">Cote D'Ivoire</option>
                          <option value="Croatia">Croatia</option>
                          <option value="Cuba">Cuba</option>
                          <option value="Curacao">Curacao</option>
                          <option value="Cyprus">Cyprus</option>
                          <option value="Czech Republic">Czech Republic</option>
                          <option value="Denmark">Denmark</option>
                          <option value="Djibouti">Djibouti</option>
                          <option value="Dominica">Dominica</option>
                          <option value="Dominican Republic">
                            Dominican Republic
                          </option>
                          <option value="Ecuador">Ecuador</option>
                          <option value="Egypt">Egypt</option>
                          <option value="El Salvador">El Salvador</option>
                          <option value="Equatorial Guinea">
                            Equatorial Guinea
                          </option>
                          <option value="Eritrea">Eritrea</option>
                          <option value="Estonia">Estonia</option>
                          <option value="Ethiopia">Ethiopia</option>
                          <option value="Falkland Islands (Malvinas)">
                            Falkland Islands (Malvinas)
                          </option>
                          <option value="Faroe Islands">Faroe Islands</option>
                          <option value="Fiji">Fiji</option>
                          <option value="Finland">Finland</option>
                          <option value="France">France</option>
                          <option value="French Guiana">French Guiana</option>
                          <option value="French Polynesia">
                            French Polynesia
                          </option>
                          <option value="French Southern Territories">
                            French Southern Territories
                          </option>
                          <option value="Gabon">Gabon</option>
                          <option value="Gambia">Gambia</option>
                          <option value="Georgia">Georgia</option>
                          <option value="Germany">Germany</option>
                          <option value="Ghana">Ghana</option>
                          <option value="Gibraltar">Gibraltar</option>
                          <option value="Greece">Greece</option>
                          <option value="Greenland">Greenland</option>
                          <option value="Grenada">Grenada</option>
                          <option value="Guadeloupe">Guadeloupe</option>
                          <option value="Guam">Guam</option>
                          <option value="Guatemala">Guatemala</option>
                          <option value="Guernsey">Guernsey</option>
                          <option value="Guinea">Guinea</option>
                          <option value="Guinea-Bissau">Guinea-Bissau</option>
                          <option value="Guyana">Guyana</option>
                          <option value="Haiti">Haiti</option>
                          <option value="Heard Island and Mcdonald Islands">
                            Heard Island and Mcdonald Islands
                          </option>
                          <option value="Vatican City">Vatican City</option>
                          <option value="Honduras">Honduras</option>
                          <option value="Hong Kong">Hong Kong</option>
                          <option value="Hungary">Hungary</option>
                          <option value="Iceland">Iceland</option>
                          <option value="India">India</option>
                          <option value="Indonesia">Indonesia</option>
                          <option value="Iran">Iran</option>
                          <option value="Iraq">Iraq</option>
                          <option value="Ireland">Ireland</option>
                          <option value="Isle of Man">Isle of Man</option>
                          <option value="Israel">Israel</option>
                          <option value="Italy">Italy</option>
                          <option value="Jamaica">Jamaica</option>
                          <option value="Japan">Japan</option>
                          <option value="Jersey">Jersey</option>
                          <option value="Jordan">Jordan</option>
                          <option value="Kazakhstan">Kazakhstan</option>
                          <option value="Kenya">Kenya</option>
                          <option value="Kiribati">Kiribati</option>
                          <option value="Korea, Democratic People's Republic of">
                            Korea, Democratic People's Republic of
                          </option>
                          <option value="Korea, Republic of">
                            Korea, Republic of
                          </option>
                          <option value="Kosovo">Kosovo</option>
                          <option value="Kuwait">Kuwait</option>
                          <option value="Kyrgyzstan">Kyrgyzstan</option>
                          <option value="Lao People's Democratic Republic">
                            Lao People's Democratic Republic
                          </option>
                          <option value="Latvia">Latvia</option>
                          <option value="Lebanon">Lebanon</option>
                          <option value="Lesotho">Lesotho</option>
                          <option value="Liberia">Liberia</option>
                          <option value="Libyan Arab Jamahiriya">
                            Libyan Arab Jamahiriya
                          </option>
                          <option value="Liechtenstein">Liechtenstein</option>
                          <option value="Lithuania">Lithuania</option>
                          <option value="Luxembourg">Luxembourg</option>
                          <option value="Macao">Macao</option>
                          <option value="Macedonia, the Former Yugoslav Republic of">
                            Macedonia, the Former Yugoslav Republic of
                          </option>
                          <option value="Madagascar">Madagascar</option>
                          <option value="Malawi">Malawi</option>
                          <option value="Malaysia">Malaysia</option>
                          <option value="Maldives">Maldives</option>
                          <option value="Mali">Mali</option>
                          <option value="Malta">Malta</option>
                          <option value="Marshall Islands">
                            Marshall Islands
                          </option>
                          <option value="Martinique">Martinique</option>
                          <option value="Mauritania">Mauritania</option>
                          <option value="Mauritius">Mauritius</option>
                          <option value="Mayotte">Mayotte</option>
                          <option value="Mexico">Mexico</option>
                          <option value="Micronesia, Federated States of">
                            Micronesia, Federated States of
                          </option>
                          <option value="Moldova, Republic of">
                            Moldova, Republic of
                          </option>
                          <option value="Monaco">Monaco</option>
                          <option value="Mongolia">Mongolia</option>
                          <option value="Montenegro">Montenegro</option>
                          <option value="Montserrat">Montserrat</option>
                          <option value="Morocco">Morocco</option>
                          <option value="Mozambique">Mozambique</option>
                          <option value="Myanmar">Myanmar</option>
                          <option value="Namibia">Namibia</option>
                          <option value="Nauru">Nauru</option>
                          <option value="Nepal">Nepal</option>
                          <option value="Netherlands">Netherlands</option>
                          <option value="Netherlands Antilles">
                            Netherlands Antilles
                          </option>
                          <option value="New Caledonia">New Caledonia</option>
                          <option value="New Zealand">New Zealand</option>
                          <option value="Nicaragua">Nicaragua</option>
                          <option value="Niger">Niger</option>
                          <option value="Nigeria">Nigeria</option>
                          <option value="Niue">Niue</option>
                          <option value="Norfolk Island">Norfolk Island</option>
                          <option value="Northern Mariana Islands">
                            Northern Mariana Islands
                          </option>
                          <option value="Norway">Norway</option>
                          <option value="Oman">Oman</option>
                          <option value="Pakistan">Pakistan</option>
                          <option value="Palau">Palau</option>
                          <option value="Palestine">Palestine</option>
                          <option value="Panama">Panama</option>
                          <option value="Papua New Guinea">
                            Papua New Guinea
                          </option>
                          <option value="Paraguay">Paraguay</option>
                          <option value="Peru">Peru</option>
                          <option value="Philippines">Philippines</option>
                          <option value="Pitcairn">Pitcairn</option>
                          <option value="Poland">Poland</option>
                          <option value="Portugal">Portugal</option>
                          <option value="Puerto Rico">Puerto Rico</option>
                          <option value="Qatar">Qatar</option>
                          <option value="Reunion">Reunion</option>
                          <option value="Romania">Romania</option>
                          <option value="Russian Federation">
                            Russian Federation
                          </option>
                          <option value="Rwanda">Rwanda</option>
                          <option value="Saint Barthelemy">
                            Saint Barthelemy
                          </option>
                          <option value="Saint Helena">Saint Helena</option>
                          <option value="Saint Kitts and Nevis">
                            Saint Kitts and Nevis
                          </option>
                          <option value="Saint Lucia">Saint Lucia</option>
                          <option value="Saint Martin">Saint Martin</option>
                          <option value="Saint Pierre and Miquelon">
                            Saint Pierre and Miquelon
                          </option>
                          <option value="Saint Vincent and the Grenadines">
                            Saint Vincent and the Grenadines
                          </option>
                          <option value="Samoa">Samoa</option>
                          <option value="San Marino">San Marino</option>
                          <option value="Sao Tome and Principe">
                            Sao Tome and Principe
                          </option>
                          <option value="Saudi Arabia">Saudi Arabia</option>
                          <option value="Senegal">Senegal</option>
                          <option value="Serbia">Serbia</option>
                          <option value="Serbia and Montenegro">
                            Serbia and Montenegro
                          </option>
                          <option value="Seychelles">Seychelles</option>
                          <option value="Sierra Leone">Sierra Leone</option>
                          <option value="Singapore">Singapore</option>
                          <option value="Sint Maarten">Sint Maarten</option>
                          <option value="Slovakia">Slovakia</option>
                          <option value="Slovenia">Slovenia</option>
                          <option value="Solomon Islands">
                            Solomon Islands
                          </option>
                          <option value="Somalia">Somalia</option>
                          <option value="South Africa">South Africa</option>
                          <option value="South Georgia and the South Sandwich Islands">
                            South Georgia and the South Sandwich Islands
                          </option>
                          <option value="South Sudan">South Sudan</option>
                          <option value="Spain">Spain</option>
                          <option value="Sri Lanka">Sri Lanka</option>
                          <option value="Sudan">Sudan</option>
                          <option value="Suriname">Suriname</option>
                          <option value="Svalbard and Jan Mayen">
                            Svalbard and Jan Mayen
                          </option>
                          <option value="Swaziland">Swaziland</option>
                          <option value="Sweden">Sweden</option>
                          <option value="Switzerland">Switzerland</option>
                          <option value="Syrian Arab Republic">
                            Syrian Arab Republic
                          </option>
                          <option value="Taiwan, Province of China">
                            Taiwan, Province of China
                          </option>
                          <option value="Tajikistan">Tajikistan</option>
                          <option value="Tanzania, United Republic of">
                            Tanzania, United Republic of
                          </option>
                          <option value="Thailand">Thailand</option>
                          <option value="Timor-Leste">Timor-Leste</option>
                          <option value="Togo">Togo</option>
                          <option value="Tokelau">Tokelau</option>
                          <option value="Tonga">Tonga</option>
                          <option value="Trinidad and Tobago">
                            Trinidad and Tobago
                          </option>
                          <option value="Tunisia">Tunisia</option>
                          <option value="Turkey">Turkey</option>
                          <option value="Turkmenistan">Turkmenistan</option>
                          <option value="Turks and Caicos Islands">
                            Turks and Caicos Islands
                          </option>
                          <option value="Tuvalu">Tuvalu</option>
                          <option value="Uganda">Uganda</option>
                          <option value="Ukraine">Ukraine</option>
                          <option value="United Arab Emirates">
                            United Arab Emirates
                          </option>
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="United States">United States</option>
                          <option value="United States Minor Outlying Islands">
                            United States Minor Outlying Islands
                          </option>
                          <option value="Uruguay">Uruguay</option>
                          <option value="Uzbekistan">Uzbekistan</option>
                          <option value="Vanuatu">Vanuatu</option>
                          <option value="Venezuela">Venezuela</option>
                          <option value="Viet Nam">Viet Nam</option>
                          <option value="Virgin Islands, British">
                            Virgin Islands, British
                          </option>
                          <option value="Virgin Islands, U.s.">
                            Virgin Islands, U.s.
                          </option>
                          <option value="Wallis and Futuna">
                            Wallis and Futuna
                          </option>
                          <option value="Western Sahara">Western Sahara</option>
                          <option value="Yemen">Yemen</option>
                          <option value="Zambia">Zambia</option>
                          <option value="Zimbabwe">Zimbabwe</option>
                        </select>

                        <button
                          type="button"
                          className="btn-register"
                          onClick={nextStep}
                          disabled={
                            newUser.password === "" ||
                            newUser.country === "" ||
                            !validationResults.length ||
                            !validationResults.lowerUppercase ||
                            !validationResults.number ||
                            !validationResults.specialChar ||
                            !validationResults.noSpaceOrRestrictedChar ||
                            confirmPassword !== newUser.password
                          }
                        >
                          Next
                        </button>

                        {isGoogleAuth === false && (
                          <div>
                            <button className="btn-previous" onClick={prevStep}>
                              Previous
                            </button>
                          </div>
                        )}

                        {showMessage && (
                          <div id="message">
                            <h3 className="message-topic">
                              Create a password that:
                            </h3>
                            <p
                              className={ validationResults.length ? "valid" : "invalid" }
                            >
                              Contains 8 - 25 characters
                            </p>
                            <p
                              className={ validationResults.lowerUppercase ? "valid" : "invalid" }
                            >
                              Contains both lower (a-z) and upper case letters
                              (A-Z)
                            </p>
                            <p
                              className={ validationResults.number ? "valid" : "invalid" }
                            >
                              Contains at least one number (0-9)
                            </p>
                            <p
                              className={ validationResults.specialChar ? "valid" : "invalid" }
                            >
                              Contains at least one special character e.g.{" "}
                              <span style={{ fontWeight: "bold" }}>  -!@#?()&/\\'" </span>
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                    <div>
                      {isGoogleAuth === false && (
                        <p className="title-3">
                          Already have an account ?{" "}
                          <span>
                            <Link to="/login"> Log In</Link>
                          </span>
                        </p>
                      )}
                      <p className="all-right">MoofLife © {currentYear}</p>
                    </div>
                  </section>
                </div>
              </Card.Body>
            </Card>{" "}
          </Col>
        </Row>

        <div className="register-container"></div>
      </Container>
    </div>
  );
};

export default RegisterPage;
