import React, { useEffect, useState } from "react";
import "../../styles/LandingPage/TopMomentsStyle.css";
import Slider from "react-slick";
import { images } from "../../utils/image";
import { useDispatch, useSelector } from "react-redux";
import { fetchTopMoments } from "../../store/action/TopMomentsAction";
import moment from "moment";
import { Link } from "react-router-dom";

const TopMoments = () => {
  const dispatch = useDispatch();
  const currentDate = new Date();

  const TopMoments = useSelector((state) => state.TopMoments.TopMoments);

  const [featured, setFeatured] = useState([]);

  const [isFeatureRecevied, seIsFeatureRecevied] = useState(false);

  useEffect( () => {
    const momentDate = moment(currentDate).format("YYYY-MM-DD");

    dispatch( fetchTopMoments(momentDate)).then((res) => {
      if (res) {
        setFeatured(res);
        seIsFeatureRecevied(true);
      }
    });
  }, []);

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      ></div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      ></div>
    );
  };

  const isInfinite = TopMoments.length <= 1;

  const settings = {
    // dots: false,
    // infinite: isInfinite ? false : true,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    // autoplay: true,
    // speed: 4000,
    // autoplaySpeed: 11000,
    dots: false,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 6000,
    prevArrow: (
      <button onClick={SamplePrevArrow}>
        <i
          class="bi bi-chevron-double-left"
          style={{ color: "#C8C8C8", fontSize: "20px" }}
        ></i>
      </button>
    ),

    nextArrow: (
      <button onClick={SampleNextArrow}>
        <i
          class="bi bi-chevron-double-right"
          style={{ color: "#C8C8C8", fontSize: "20px" }}
        ></i>
      </button>
    ),
  };

  const innerSettings = {
    dots: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: isInfinite ? false : true,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 578,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],

    afterChange: (currentSlide) => (
      <button onClick={() => console.log("after change", currentSlide)}>
        <i
          className="bi bi-chevron-double-right"
          style={{ color: "white", fontSize: "60px" }}
        ></i>
      </button>
    ),
  };

  const slideOne = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 578,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: (currentSlide) => (
      <button onClick={() => console.log("after change", currentSlide)}>
        <i
          className="bi bi-chevron-double-right"
          style={{ color: "white", fontSize: "60px" }}
        ></i>
      </button>
    ),
  };

  return (
    <div className="landing-top-moment-main">
      <Slider {...settings}>
        {featured.length > 0 &&
          featured.map((topMoment, index) =>
            topMoment.images?.length > 0 &&
            topMoment.featured === true &&
            topMoment.images[0]?.media_type === "image" ? (
              <div key={index} className="content">
                <img src={topMoment.images[0].url} alt="topmomentImage" />
                <div className="title-row">
                  <Link
                    to={`/${
                      topMoment?.life?.verified_name || topMoment?.life?.life_id
                    }/${topMoment._id}`}
                    target="_blank"
                  >
                    <div className="title">{topMoment.moment_title}</div>
                  </Link>

                  <div className="word">Featured Moment</div>
                </div>
              </div>
            ) : null
          )}

        {isFeatureRecevied && (
          <div className="content">
            <img src={images.TopMoment2} />
            <div className="title-row">
              <div className="title">
                Capture your life moments with MoofLife and cherish each
                precious moment that has shaped you.
              </div>
              <Link to="/login">
                <div className="word">Log in to engage with Life Moments.</div>
              </Link>
            </div>
          </div>
        )}

        {isFeatureRecevied && (
          <div className="content">
            <img src={images.TopMoment3} />
            <div className="title-row">
              <div className="title">
                Capture your life moments with MoofLife and cherish each
                precious moment that has shaped you.
              </div>
              <Link to="/select-package">
                <div className="word">Create your Life Moments</div>
              </Link>
            </div>
          </div>
        )}

        {isFeatureRecevied && (
          <div className="content">
            <img src={images.TopMoment4} />
            <div className="title-row">
              <div className="title">
                Capture your life moments with MoofLife and cherish each
                precious moment that has shaped you.
              </div>
              <Link to="/select-package">
                <div className="word">Create your Life Moments</div>
              </Link>
            </div>
          </div>
        )}

        {isFeatureRecevied && (
          <div className="content">
            <img src={images.TopMoment5} />
            <div className="title-row">
              <div className="title">
                Capture your life moments with MoofLife and cherish each
                precious moment that has shaped you.
              </div>
              <Link to="/select-package">
                <div className="word">Create your Life Moments</div>
              </Link>
            </div>
          </div>
        )}

        {isFeatureRecevied && (
          <div className="content">
            <img src={images.TopMoment6} />
            <div className="title-row">
              <div className="title">
                Capture your life moments with MoofLife and cherish each
                precious moment that has shaped you.
              </div>
              <Link to="/select-package">
                <div className="word">Create your Life Moments</div>
              </Link>
            </div>
          </div>
        )}

        {isFeatureRecevied && (
          <div className="content">
            <img src={images.TopMoment7} />
            <div className="title-row">
              <div className="title">
                Capture your life moments with MoofLife and cherish each
                precious moment that has shaped you.
              </div>
              <Link to="/select-package">
                <div className="word">Create your Life Moments</div>
              </Link>
            </div>
          </div>
        )}

        {isFeatureRecevied && (
          <div className="content">
            <img src={images.TopMoment8} />
            <div className="title-row">
              <div className="title">
                Capture your life moments with MoofLife and cherish each
                precious moment that has shaped you.
              </div>
              <Link to="/select-package">
                <div className="word">Create your Life Moments</div>
              </Link>
            </div>
          </div>
        )}

        {isFeatureRecevied && (
          <div className="content">
            <img src={images.TopMoment9} />
            <div className="title-row">
              <div className="title">
                Capture your life moments with MoofLife and cherish each
                precious moment that has shaped you.
              </div>
              <Link to="/select-package">
                <div className="word">Create your Life Moments</div>
              </Link>
            </div>
          </div>
        )}

        {isFeatureRecevied && (
          <div className="content">
            <img src={images.TopMoment10} />
            <div className="title-row">
              <div className="title">
                Capture your life moments with MoofLife and cherish each
                precious moment that has shaped you.
              </div>
              <Link to="/select-package">
                <div className="word">Create your Life Moments</div>
              </Link>
            </div>
          </div>
        )}
      </Slider>

      {TopMoments.length > 1 && (
        <div className="inner-slide">
          <div>
            <div className="title-1">On this day</div>
            <Slider {...innerSettings}>
              {TopMoments.map((topMoment, index) =>
                topMoment.images?.length > 0 &&
                topMoment.images[0]?.media_type === "image" ? (
                  <div key={index}>
                    <img
                      className="small-slider-image"
                      src={topMoment.images[0].url}
                      alt="topmomentImage"
                    />
                    <div className="title-row">
                      <Link
                        to={`/${
                          topMoment.life.verified_name
                            ? topMoment.life.verified_name
                            : topMoment.life.life_id
                        }`}
                      >
                        <div className="date">
                          {topMoment.life?.life_name.length > 10
                            ? topMoment.life.life_name.slice(0, 10) + "..."
                            : topMoment.life.life_name}{" "}
                          : {topMoment.moment_date}
                        </div>
                      </Link>
                      <Link
                        to={`/${
                          topMoment?.life?.verified_name ||
                          topMoment?.life?.life_id
                        }/${topMoment._id}`}
                        target="_blank"
                      >
                        <div className="title">
                          {topMoment.moment_title.length > 45
                            ? topMoment.moment_title.slice(0, 45) + "..."
                            : topMoment.moment_title}
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : null
              )}
            </Slider>
          </div>
        </div>
      )}

      {TopMoments.length === 1 && (
        <div className="inner-slide slide-one">
          <div>
            {TopMoments.map(
              (topMoment, index) =>
                topMoment.images?.length > 0 &&
                topMoment.images[0]?.media_type === "image" && (
                  <div className="title-1">On this day</div>
                )
            )}

            <Slider {...slideOne}>
              {TopMoments.map((topMoment, index) =>
                topMoment.images?.length > 0 &&
                topMoment.images[0]?.media_type === "image" ? (
                  <div key={index}>
                    <Link
                      to={`/${
                        topMoment?.life?.verified_name ||
                        topMoment?.life?.life_id
                      }/${topMoment._id}`}
                      target="_blank"
                    >
                      <img
                        className="small-slider-image"
                        src={topMoment.images[0].url}
                        alt="topmomentImage"
                      />
                    </Link>
                    <div className="title-row">
                      <Link
                        to={`/${
                          topMoment.life.verified_name
                            ? topMoment.life.verified_name
                            : topMoment.life.life_id
                        }`}
                      >
                        <div className="date">
                          {topMoment.life?.life_name.length > 10
                            ? topMoment.life.life_name.slice(0, 10) + "..."
                            : topMoment.life.life_name}{" "}
                          : {topMoment.moment_date}
                        </div>
                      </Link>
                      <Link
                        to={`/${
                          topMoment?.life?.verified_name ||
                          topMoment?.life?.life_id
                        }/${topMoment._id}`}
                        target="_blank"
                      >
                        <div className="title">
                          {topMoment.moment_title.length > 45
                            ? topMoment.moment_title.slice(0, 45) + "..."
                            : topMoment.moment_title}
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : null
              )}
            </Slider>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopMoments;
