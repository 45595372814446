import React, { useEffect, useRef, useState } from "react";
import "../../styles/TopMomentTileStyle.css";
import "../../styles/LifePage/LifeMomentsStyle.css";
import "../../styles/ShareIconStyle.css";
import NavigationBar from "../../components/NavigationBar";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import VideoPlayer from "../../components/VideoPlayer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { images } from "../../utils/image";
import Dropdown from "react-bootstrap/Dropdown";
import copy from "copy-to-clipboard";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  XIcon,
} from "react-share";
import { getFromSessionStorage } from "../../utils/storageHandler";
import {
  getSelectedLifeMoments,
  getMomentsStartingFromSpecificId,
  resetLifeMoments,
  resetLife,
  resetPage,
  resetLimitReached,
  fetchLife,
  getLivesForUser,
} from "../../store/action/LifeAction";
import LifeNavbar from "./LifeNavbar";
import LifeHeader from "./LifeHeader";
import SearchPage from "./SearchPage";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import { DropdownItem, Spinner } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Comments from "../Comments/Comments";
import { updateMomentInteractionCount } from "../../store/action/MostViewedMomentsAction";
import { countryList } from "../../utils/CountryList";
import Flag from "react-world-flags";
import {
  createImpression,
  fetchImpressionSet,
} from "../../store/action/ImpressionAction";
import {
  deleteMoment,
  getAuthorizedEmails,
  getPermissionsToUpdate,
  linkMoment,
} from "../../store/action/MomentAction";
import ConfirmationPopup from "../ConfirmationPopup";
import PopUpModal from "../PopUpModal";
import Zoom from "react-medium-image-zoom";
import ReportLifeMoment from "../ReportLifeMoment";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const LifeMoments = () => {
  const currentDate = new Date();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { life_id } = useParams();
  const limit = 10;
  const [lifeData, setLifeData] = useState(null);
  const [shareBoxVisible, setShareBoxVisible] = useState(false);
  const [commentShareBoxVisible, setCommentShareBoxVisible] = useState(false);
  const [shareMoment, setShareMoment] = useState("");
  const textRef = useRef();
  const commentTextRef = useRef();
  const shareBoxRef = useRef();
  const shareCommentBoxRef = useRef();
  const [tooltipState, setTooltipState] = useState({});
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [show, setShow] = useState(false);
  const [commentMomentId, setCommentMomentId] = useState("");
  const [showImg, setShowImg] = useState(false);
  const userData = getFromSessionStorage("userDetails", true);
  const [userLogin, setUserLogin] = useState("");
  const [commentMoment, setCommentMoment] = useState("");
  const [showPopup, setShowPopUp] = useState(false);
  const [commentOpen, setCommentOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [currentMoment, setCurrentMoment] = useState("");
  const [showAllImages, setShowAllImages] = useState(false);
  const [impressionBoxVisible, setImpressionBoxVisible] = useState(false);
  const [showImpressionSet, setShowImpressionSet] = useState("");
  const [showTooltip, setTooltipShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [permissionGranted, setPermissionGrant] = useState(false);
  const [mooflifeAdmin, setMooflifeAdmin] = useState(false);
  const [livesForUser, setLivesForUser] = useState("");
  const [popupMessages, setPopupMessages] = useState({
    title: "",
    subtitle1: "",
    subtitle2: "",
    button1: "",
    button2: "",
  });
  const [isMomentDelete, setIsMomentDelete] = useState(false);
  const [confirmationPopupShow, setConfirmationPopupShow] = useState(false);
  const [momentIndex, setMomentIndex] = useState("");
  const [showLinkMomentPopup, setShowLinkMomentPopup] = useState(false);
  const [momentIdForLink, setMomentIdForLink] = useState(null);
  const [message, setMessage] = useState("");
  const [popupModalShow, setPopupModalShow] = useState(false);
  //Report Modal
  const [showReportMoment, setShowReportMoment] = useState(false);
  const [reportMomentId, setReportMomentId] = useState("");
  const [selectedLifeId, setSelectedLifeId] = useState(null);
  const [lifeIdForLink, setLifeIdForLink] = useState(null);
  const [loadingLink, setLoadingLink] = useState(false);
  const LifeMoments = useSelector((state) => state.Life.LifeMoments);
  const lifeMomentPage = useSelector((state) => state.Life.Page);
  const params = useParams();
  const isLifePage = params && params.life_id ? true : false;
  const selectedMoments = isLifePage ? LifeMoments : [];
  const limitReached = useSelector((state) => state.Moments.limitReached);

  const lifeMomentLimitReached = useSelector(
    (state) => state.Life.limitReached
  );
  const isLimitReached = isLifePage ? lifeMomentLimitReached : limitReached;
  const selectedLife = useSelector((state) => state.Life.Life);
  let isLifeStartingFromSpecificMoment =
    params && params.moment_id ? true : false;
  const momentSorting = useSelector((state) => state.Life.momentSorting);

  const [maxLength, setMaxLength] = useState(180);
  const [maxLargeLength, setLargeMaxLength] = useState();
  const [maxLargeImage, setLargeImage] = useState();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 378) {
        setMaxLength(480);
        setLargeMaxLength(800);
        setLargeImage(500);
      } else if (window.innerWidth < 420) {
        setMaxLength(600); 
        setLargeMaxLength(1100);
        setLargeImage(600);
      } else if (window.innerWidth < 478) {
        setMaxLength(650);
        setLargeMaxLength(1200);
        setLargeImage(650); 
      }else if (window.innerWidth < 578) {
        setMaxLength(850);
        setLargeMaxLength(1500);
        setLargeImage(750); 
      } else if (window.innerWidth <= 800) {
        setMaxLength(400);
        setLargeMaxLength(2300);
        setLargeImage(900);
      }
      else if (window.innerWidth <= 990) {
        setMaxLength(250);
        setLargeMaxLength(1700);
        setLargeImage(600);
      } else if (window.innerWidth <= 1250) {
        setMaxLength(400);
        setLargeMaxLength(2000);
        setLargeImage(700);
      } else if (window.innerWidth > 1600 && window.innerWidth < 1700) {
        setMaxLength(230);
        setLargeMaxLength(1200);
        setLargeImage(600);
      } else {
        setMaxLength(195); 
        setLargeMaxLength(1200);
        setLargeImage(480);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial value

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    dispatch(resetLife());
    dispatch(resetLifeMoments());
    dispatch(resetPage());
    dispatch(resetLimitReached());
    dispatch(fetchLife(life_id)).then((response) => {
      if (!response.data.success) {
        navigate("/404");
      } else {
        if (response && response.data && response.data.success) {
          setLifeData(response.data.data);
        }
      }
    });
  }, [life_id]);

  useEffect(() => {
    const userDetails = getFromSessionStorage("userDetails", true);
    setUserLogin(userDetails);
  }, []);

  useEffect(() => {
    dispatch(resetLifeMoments());
    if (lifeData && lifeData._id) {
      loadItems();
    }
  }, [lifeData]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (shareBoxRef.current && !shareBoxRef.current.contains(event.target)) {
        setShareBoxVisible(false);
        setImpressionBoxVisible(false);
      }
      if (
        shareCommentBoxRef.current &&
        !shareCommentBoxRef.current.contains(event.target)
      ) {
        setCommentShareBoxVisible(false);
        setImpressionBoxVisible(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const userDetails = getFromSessionStorage("userDetails", true);
    setUserLogin(userDetails);
  }, []);

  useEffect(() => {
    const userDetails = getFromSessionStorage("userDetails", true);
    setUserLogin(userDetails);

    if (userDetails && userDetails.email) {
      requestLivesForUser(userDetails);
      checkAuthorizedEmails(userDetails);
    }
  }, []);

  const handleCommentOpen = (momentId) => {
    setCommentMoment(momentId);
    if (!userLogin) {
      setShowPopUp(true);
    }

    if (commentMoment === "" || commentMoment === momentId) {
      setCommentOpen(!commentOpen);
    }
  };

  const currentUrl =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "");

    const momentsByYear = {};

    // Function to extract the year from a date string
    const extractYear = (dateStr) => {
      // Handle case where only the year is present (e.g., "1655")
      const yearOnlyMatch = dateStr.match(/^\d{4}$/);
      if (yearOnlyMatch) {
        return parseInt(yearOnlyMatch[0], 10);
      }
    
      // Handle case like "Jun-1655" or "June-1655"
      const yearMonthMatch = dateStr.match(/(?:[a-zA-Z]+)-(\d{4})/);
      if (yearMonthMatch) {
        return parseInt(yearMonthMatch[1], 10);
      }
    
      // Default to standard date parsing if available
      const date = new Date(dateStr);
      const year = date.getFullYear();
    
      // Return NaN if date is invalid
      return isNaN(year) ? NaN : year;
    };
    
    // Iterate over selectedMoments to group moments dynamically
    selectedMoments.forEach((moment) => {
      const year = extractYear(moment.moment_date); // Use custom extractYear function
      if (!isNaN(year)) { // Ensure the year is valid
        if (!momentsByYear[year]) {
          momentsByYear[year] = [];
        }
        momentsByYear[year].push(moment);
      } else {
        console.warn(`Invalid date: ${moment.moment_date}`);
      }
    });
    

  const isLargeTile = (index) => {
    // pattern - 1, 6, 11, 16, 21
    // 6 / 5 remaining 1

    if (selectedMoments[index]?.images[0]?.media_type === "video") {
      return true;
    } else {
      if (
        index === 0 ||
        (selectedMoments[index]?.images[0]?.url && index % 5 === 0)
      ) {
        return true;
      } else if (
        index % 5 === 1 &&
        !selectedMoments[index - 1]?.images[0]?.url &&
        selectedMoments[index]?.images[0]?.url
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleShareClick = (momentId) => {
    setShareMoment(momentId);
    setShareBoxVisible(true);
  };

  const handleCommentModalShareClick = (momentId) => {
    setShareMoment(momentId);
    setCommentShareBoxVisible(true);
  };

  const copyToClipboard = (id, moments) => {
    let copyText = textRef.current.value;
    let isCopy = copy(copyText);
    if (id === moments._id && isCopy) {
      toggleTooltip(id, true);

      setTimeout(() => {
        toggleTooltip(id, false);
      }, 3000);
    }
  };

  const copyToCommentClipboard = (id, selectedCommentMoment) => {
    let copyText = commentTextRef.current.value;
    let isCopy = copy(copyText);
    if (id === selectedCommentMoment._id && isCopy) {
      toggleCommentModalTooltip(id, true);

      setTimeout(() => {
        toggleCommentModalTooltip(id, false);
      }, 3000);
    }
  };

  const toggleTooltip = (momentId, value) => {
    setTooltipState((prevState) => ({
      ...prevState,
      [momentId]: value,
    }));
  };

  const toggleCommentModalTooltip = (momentId, value) => {
    setTooltipShow((prevState) => ({
      ...prevState,
      [momentId]: value,
    }));
  };

  const loadItems = async () => {
    const userDetails = getFromSessionStorage("userDetails", true);
    const loggedInUserId = userDetails?.id ? userDetails.id : null;
    if (isLifePage && selectedLife._id) {
      if (isLifeStartingFromSpecificMoment) {
        dispatch(
          getMomentsStartingFromSpecificId(
            {
              //page: lifeMomentPage,
              momentId: params.moment_id,
              loggedInUserId: loggedInUserId,
              user_email: userDetails?.email ? userDetails.email : "",
              sorting: momentSorting ? momentSorting : -1,
            }
            // lifeMomentPage
          )
        );
      } else {
        dispatch(
          getSelectedLifeMoments(
            {
              selector: { life: lifeData._id },
              page: lifeMomentPage,
              limit: limit,
              loggedInUserId: loggedInUserId,
              sorting: momentSorting ? momentSorting : -1,
              life_id: lifeData ? lifeData.life_id : null,
              connectors: lifeData ? lifeData.connected_lives : [],
              user_email: userDetails?.email ? userDetails.email : "",
            },
            lifeMomentPage
          )
        );
      }
    }
  };

  const handleClose = () => {
    setShowImg(false);
    setShowPopUp(false);
    setShow(false);
  };

  const handleShow = (id) => {
    setCommentMomentId(id);
    setShow(true);
    setShowCommentModal(true);
  };

  const handleCommentModal = (id) => {
    setCommentMomentId(id);
    setShowCommentModal(true);
  };

  const handleCloseCommentModal = () => {
    setShowCommentModal(false);
    setCommentShareBoxVisible(false);
  };

  const selectedCommentMoment = selectedMoments.find(
    (moment) => moment._id === commentMomentId
  );

  const getEmojiAsset = (type) => {
    switch (type) {
      case "like":
        return images.LikeIcon;
      case "great":
        return images.GreatIcon;
      case "emotional":
        return images.EmotionalIcon;
      case "nostalgic":
        return images.NostalgicIcon;
      case "wish":
        return images.WishIcon;
      case "part":
        return images.PartOfTheMomentIcon;
      case "witnessed":
        return images.WitnessedIcon;
      default:
        return null;
    }
  };

  const toggleShow = (id, action) => {
    setCurrentMoment(id);
    setShowMore(action === "less" ? false : true);
    dispatch(updateMomentInteractionCount({ moment_id: id }));
  };

  //handle impressions
  const handleEmojiClick = (type, momentId) => {
    dispatch(
      createImpression({
        moment_id: momentId,
        loggedInUserId: userData?.id,
        impression_type: type,
        page: "landing",
      })
    );
  };

  const totalFeedbacks = (reactionCounts) => {
    if (reactionCounts) {
      return Object.values(reactionCounts).reduce(
        (sum, count) => sum + count,
        0
      );
    }
  };

  const handleImpressionClick = () => {
    if (!userLogin) {
      setShowPopUp(true);
    } else setImpressionBoxVisible(!impressionBoxVisible);
  };

  const getTopReactions = (reactionCounts) => {
    // Create an array of objects with reaction type and count
    if (reactionCounts) {
      const reactionArray = Object.entries(reactionCounts).map(
        ([type, count]) => ({ type, count })
      );

      // Sort the array based on count in descending order
      reactionArray.sort((a, b) => b.count - a.count);

      // Get the top 3 reaction types
      const topReactions = reactionArray
        .filter((reaction) => reaction.count > 0)
        .slice(0, 3);

      // Return an array containing only the reaction types
      return topReactions.map((reaction) => reaction.type);
    }
  };

  const handleImpressionSet = (id) => {
    setShowImpressionSet(true);
    dispatch(fetchImpressionSet(id));
    setShowCommentModal(false);
  };

  const checkAuthorizedEmails = async (userDetails) => {
    if (userDetails && userDetails.email) {
      dispatch(
        await getAuthorizedEmails({ userEmail: userDetails.email })
      ).then((res) => {
        if (res && res.success && res.data) {
          setMooflifeAdmin(res.data.result);
        }
      });
    }
  };

  const requestLivesForUser = async (userDetails) => {
    dispatch(await getLivesForUser({ email: userDetails.email })).then(
      (res) => {
        if (res && res.success && res.data) {
          setLivesForUser(res.data);
        }
      }
    );
  };

  const handleDeleteMoment = (momentId) => {
    dispatch(
      deleteMoment({
        moment_id: momentId,
      })
    ).then(async (res) => {
      if (res && res.success) {
        //delete successfull
        setPopupModalShow(true);
        setIsMomentDelete(true);
        setMessage("Delete Successful");

        setTimeout(() => {
          setPopupModalShow(false);
        }, 3000);
      } else {
        //delete fail
        setPopupModalShow(true);
        setIsMomentDelete(true);
        setMessage("Delete Fail");

        setTimeout(() => {
          setPopupModalShow(false);
        }, 3000);
      }
    });
  };

  const handleDropDown = async (isOpen, life) => {
    setLoading(true);
    if (isOpen && life) {
      dispatch(
        await getPermissionsToUpdate({
          lifeId: life.life_id,
          userEmail: userData.email,
        })
      ).then((permission) => {
        setPermissionGrant(permission);
        setLoading(false);
      });
    }
  };

  const showReportModal = (_id) => {
    setReportMomentId(_id);
    setShowReportMoment(true);
  };

  const updateMoment = (momentId) => {
    navigate(`/update-moment/${momentId}`);
  };

  const handleConfirmationPopupShow = (momentId) => {
    setPopupMessages({
      title: "Warning!",
      subtitle1: "Are you sure you want to delete this moment? ",
      subtitle2:
        "This action is irreversible and the image cannot be recovered once deleted.",
      button1: "Delete",
      button2: "Cancel",
    });
    setIsMomentDelete(true);
    setConfirmationPopupShow(true);
    setMomentIndex(momentId);
  };

  const handleLinkMomentPopupOpen = (momentId) => {
    if (momentId) {
      setShowLinkMomentPopup(true);
      setMomentIdForLink(momentId);
    }
  };

  const showPopupModal = () => {
    if (!userLogin) {
      setShowPopUp(true);
    } else {
      setShowPopUp(false);
    }
  };

  const handleLinkMoment = async () => {
    setLoadingLink(true);
    if (momentIdForLink && lifeIdForLink) {
      const linkMomentRes = await dispatch(
        linkMoment({ momentId: momentIdForLink, lifeId: lifeIdForLink })
      );
      if (linkMomentRes && linkMomentRes.success && linkMomentRes.data) {
        const message = linkMomentRes.data.message
          ? linkMomentRes.data.message
          : "Moment connected with the Life successfully!";
        setMessage(message);
        setPopupModalShow(true);
        setLoadingLink(false);
        setShowLinkMomentPopup(false);
        setSelectedLifeId("");

        setTimeout(() => {
          setPopupModalShow(false);
        }, 3000);
      } else {
        let error = null;
        if (linkMomentRes.error) {
          error = linkMomentRes.error;
        }

        const errorMsg = error
          ? error
          : "Failed to connect the Moment with selected Life!";
        setMessage(errorMsg);
        setPopupModalShow(true);
        setLoadingLink(false);
        setShowLinkMomentPopup(false);
        setSelectedLifeId("");

        setTimeout(() => {
          setPopupModalShow(false);
        }, 3000);
      }
    }
  };

  const handleLinkMomentPopupClose = () => {
    setShowLinkMomentPopup(false);
    setSelectedLifeId("");
  };

  const selectLifeForLink = (lifeId) => {
    setSelectedLifeId(lifeId);

    if (lifeId) {
      setLifeIdForLink(lifeId);
      // handleCheck(lifeId);
    }
  };

  const renderTooltip = (props) => <Tooltip {...props}>Simple tooltip</Tooltip>;

  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);

  const handleShowModal = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const renderLayout = (moments) => {
    if (moments.length === 0) {
      return null;
    }
    let layout = [];
    let remainingPost = moments.length;
    let startIndex = 0;

    while (remainingPost > 0) {
      let chunkSize = Math.min(remainingPost, 8);
      const slicedArray = moments.slice(startIndex, startIndex + chunkSize);
      layout.push(generatePost(slicedArray));

      startIndex += chunkSize;
      remainingPost -= chunkSize;
    }
    return layout;
  };

  const generatePost = (momentArray) => {
    let postArray = [];
    {
      momentArray.map((moments, index) =>
        momentArray.length === 1
          ? postArray.push(
              <div
                key={moments._id}
                className={`tile ${isLargeTile(index) ? "large" : "large"}`}
              >
                {userLogin ? (
                  <Dropdown
                    onToggle={(isOpen) => handleDropDown(isOpen, moments.life)}
                  >
                    <Dropdown.Toggle id="dropdown-basic">
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>More</Tooltip>}
                      >
                        <img src={images.MoofIcon} className="moof-icon"></img>
                      </OverlayTrigger>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {loading ? (
                        <Spinner
                          animation="border"
                          variant="dark"
                          style={{
                            width: "15px",
                            height: "15px",
                            textAlign: "center",
                            margin: "auto",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        />
                      ) : !permissionGranted ? (
                        <Dropdown.Item
                          onClick={() => showReportModal(moments._id)}
                        >
                          Report Moment
                        </Dropdown.Item>
                      ) : (
                        <>
                          <Dropdown.Item
                            onClick={() => updateMoment(moments._id)}
                          >
                            Update Moment
                          </Dropdown.Item>
                          <DropdownItem
                            // onClick={() => handleDeleteMoment(moment._id)}
                            onClick={() =>
                              handleConfirmationPopupShow(moments._id)
                            }
                          >
                            Delete Moment
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              handleLinkMomentPopupOpen(moments._id)
                            }
                          >
                            Connect to my Life
                          </DropdownItem>
                        </>
                      )}
                      {moments.images.length > 0 && (
                        <DropdownItem
                          onClick={() => handleShowModal(moments.images[0])}
                        >
                          Copyright Info
                        </DropdownItem>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <OverlayTrigger
                    key="left"
                    placement="left"
                    overlay={<Tooltip>More</Tooltip>}
                  >
                    <img
                      src={images.MoofIcon}
                      onClick={showPopupModal}
                      className="moof-icon"
                    ></img>
                  </OverlayTrigger>
                )}

                {tooltipState[moments._id] && (
                  <div className="copy-message ">Link copied</div>
                )}
                {moments.shareable === true && (
                  <div className="icons share-icon">
                    <div class="share-feed">
                      <a
                        class="share-btn"
                        onClick={() => handleShareClick(moments._id)}
                      >
                        {shareBoxVisible && shareMoment === moments._id && (
                          <div class="share-box" ref={shareBoxRef}>
                            <div class="share-icon">
                              <label>Telegram</label>
                              <TelegramShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                              >
                                <TelegramIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </TelegramShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Linkedin</label>
                              <LinkedinShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                                source="MoofLife"
                              >
                                <LinkedinIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </LinkedinShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Whatsapp</label>
                              <WhatsappShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                                separator=":: "
                              >
                                <WhatsappIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </WhatsappShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Facebook</label>
                              <FacebookShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                              >
                                <FacebookIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </FacebookShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Mask</label>
                              <TwitterShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                              >
                                <XIcon size={32} round className="icon" />
                              </TwitterShareButton>
                            </div>
                            <div class="share-icon icon-copy">
                              <div class="hover-text">
                                {/* <!-- Copy Text --> */}
                                <div className="copy-text d-flex">
                                  <input
                                    value={`${currentUrl}/${
                                      moments.life.verified_name ||
                                      moments.life.life_id
                                    }/${moments._id}`}
                                    disabled
                                    type="text"
                                    ref={textRef}
                                  />
                                </div>
                              </div>
                              <img
                                src={images.CopyIcon}
                                onClick={() =>
                                  copyToClipboard(moments._id, moments)
                                }
                                className="icon"
                              ></img>
                            </div>
                          </div>
                        )}
                      </a>
                    </div>
                  </div>
                )}

                {moments.images.length > 0 && (
                  <div className="length-1-image">
                    <div>
                      {moments.images[0]?.media_type === "image" ||
                      moments.images[0]?.media_type == null ? (
                        <>
                          <Link
                            to={`/read/${
                              moments?.life?.verified_name ||
                              moments?.life?.life_id
                            }/${moments._id}`}
                            target="_blank"
                          >
                            <img
                              className="moment-img"
                              src={moments.images[0].url}
                            />
                          </Link>
                        </>
                      ) : (
                        <div>
                          <VideoPlayer
                            className="videoFrame"
                            source={moments.images[0].url}
                          />
                        </div>
                      )}
                    </div>
                    <Link
                        to={`/read/${
                          moments?.life?.verified_name || moments?.life?.life_id
                        }/${moments._id}`}
                        target="_blank"
                      >
                    <div>
                      <div
                        className="para"
                        style={{ margin: "20px 20px", paddingRight: "80px" }}
                      >
                        <div className="large-des">
                          {moments.moment_title.length > 200
                            ? moments.moment_title.slice(0, 200) + "..."
                            : moments.moment_title}
                        </div>
                        <div>
                          {moments.moment_details.length > maxLargeImage
                            ? moments.moment_details.slice(0, maxLargeImage) +
                              "..."
                            : moments.moment_details}
                        </div>
                      </div>
                    </div>
                    </Link>
                  </div>
                )}

                <div className="title">
                  <Link
                    to={`/read/${
                      moments?.life?.verified_name || moments?.life?.life_id
                    }/${moments._id}`}
                    target="_blank"
                  >
                    <div className="p">
                      <div className="life-logo-name">
                        <img
                          src={moments.life?.life_logo}
                          className="img"
                        ></img>
                        {index === 0 && moments.images.length < 1 ? (
                          <div
                            className="moment-date"
                            style={{ color: "black" }}
                          >
                            {moments.moment_date}
                          </div>
                        ) : (
                          <div className="moment-date">
                            {moments.moment_date}
                          </div>
                        )}
                      </div>
                      {index === 0 && moments.images.length < 1 ? (
                        <div className="large-des">
                          {moments.moment_title.length > 100
                            ? moments.moment_title.slice(0, 100) + "..."
                            : moments.moment_title}
                        </div>
                      ) : (
                        ""
                      )}
                      <div>
                        {moments.images.length < 1 && index === 0 && (
                          <div className="para">
                            <div>
                              {moments.moment_details.length > maxLargeLength
                                ? moments.moment_details.slice(
                                    0,
                                    maxLargeLength
                                  ) + "..."
                                : moments.moment_details}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Link>

                  <div
                    className="reaction-icons-large"
                    style={{
                      color:
                        index === 0 && moments.images.length < 1
                          ? "#A8A8A8"
                          : "#A8A8A8",
                    }}
                  >
                    <OverlayTrigger
                      key="left"
                      placement="left"
                      overlay={<Tooltip>Like</Tooltip>}
                    >
                      <div
                        className="mt-2"
                        onClick={() => handleShow(moments._id)}
                      >
                        <i class="bi bi-hand-thumbs-up"></i>
                      </div>
                    </OverlayTrigger>
                    {moments.commentable === true && (
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Comment</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShow(moments._id)}
                        >
                          <i class="bi bi-chat-text"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                    {moments.shareable === true && (
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Share</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShareClick(moments._id)}
                        >
                          <i class="bi bi-send-fill"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>

                {moments.images.length < 1 && index === 0 && (
                  <div className="tile-footer">
                    <div className="reaction-icons d-flex gap-4">
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Like</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleCommentModal(moments._id)}
                        >
                          <i class="bi bi-hand-thumbs-up"></i>
                        </div>
                      </OverlayTrigger>
                      {moments.commentable === true && (
                        <OverlayTrigger
                          key="left"
                          placement="left"
                          overlay={<Tooltip>Comment</Tooltip>}
                        >
                          <div
                            className="mt-2"
                            onClick={() => handleCommentModal(moments._id)}
                          >
                            <i class="bi bi-chat-text"></i>
                          </div>
                        </OverlayTrigger>
                      )}
                      {moments.shareable === true && (
                        <OverlayTrigger
                          key="left"
                          placement="left"
                          overlay={<Tooltip>Share</Tooltip>}
                        >
                          <div
                            className="mt-2"
                            onClick={() => handleShareClick(moments._id)}
                          >
                            <i class="bi bi-send-fill"></i>
                          </div>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                )}
                <div className="tile-footer">
                  <div className="reaction-icons d-flex gap-4">
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip>Like</Tooltip>}
                    >
                      <div
                        className="mt-2"
                        onClick={() => handleCommentModal(moments._id)}
                      >
                        <i class="bi bi-hand-thumbs-up"></i>
                      </div>
                    </OverlayTrigger>
                    {moments.commentable === true && (
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip>Comment</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleCommentModal(moments._id)}
                        >
                          <i class="bi bi-chat-text"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                    {moments.shareable === true && (
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip>Share</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShareClick(moments._id)}
                        >
                          <i class="bi bi-send-fill"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              </div>
            )
          : momentArray.length === 2
          ? postArray.push(
              <div
                key={moments._id}
                className={`tile ${isLargeTile(index) ? "large" : "large"}`}
              >
                {userLogin ? (
                  <Dropdown
                    onToggle={(isOpen) => handleDropDown(isOpen, moments.life)}
                  >
                    <Dropdown.Toggle id="dropdown-basic">
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>More</Tooltip>}
                      >
                        <img src={images.MoofIcon} className="moof-icon"></img>
                      </OverlayTrigger>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {loading ? (
                        <Spinner
                          animation="border"
                          variant="dark"
                          style={{
                            width: "15px",
                            height: "15px",
                            textAlign: "center",
                            margin: "auto",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        />
                      ) : !permissionGranted ? (
                        <Dropdown.Item
                          onClick={() => showReportModal(moments._id)}
                        >
                          Report Moment
                        </Dropdown.Item>
                      ) : (
                        <>
                          <Dropdown.Item
                            onClick={() => updateMoment(moments._id)}
                          >
                            Update Moment
                          </Dropdown.Item>
                          <DropdownItem
                            // onClick={() => handleDeleteMoment(moment._id)}
                            onClick={() =>
                              handleConfirmationPopupShow(moments._id)
                            }
                          >
                            Delete Moment
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              handleLinkMomentPopupOpen(moments._id)
                            }
                          >
                            Connect to my Life
                          </DropdownItem>
                        </>
                      )}
                      {moments.images.length > 0 && (
                        <DropdownItem
                          onClick={() => handleShowModal(moments.images[0])}
                        >
                          Copyright Info
                        </DropdownItem>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <OverlayTrigger
                    key="left"
                    placement="left"
                    overlay={<Tooltip>More</Tooltip>}
                  >
                    <img
                      src={images.MoofIcon}
                      onClick={showPopupModal}
                      className="moof-icon"
                    ></img>
                  </OverlayTrigger>
                )}

                {tooltipState[moments._id] && (
                  <div className="copy-message ">Link copied</div>
                )}
                {moments.shareable === true && (
                  <div className="icons share-icon">
                    <div class="share-feed">
                      <a
                        class="share-btn"
                        onClick={() => handleShareClick(moments._id)}
                      >
                        {shareBoxVisible && shareMoment === moments._id && (
                          <div class="share-box" ref={shareBoxRef}>
                            <div class="share-icon">
                              <label>Telegram</label>
                              <TelegramShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                              >
                                <TelegramIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </TelegramShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Linkedin</label>
                              <LinkedinShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                                source="MoofLife"
                              >
                                <LinkedinIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </LinkedinShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Whatsapp</label>
                              <WhatsappShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                                separator=":: "
                              >
                                <WhatsappIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </WhatsappShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Facebook</label>
                              <FacebookShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                              >
                                <FacebookIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </FacebookShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Mask</label>
                              <TwitterShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                              >
                                <XIcon size={32} round className="icon" />
                              </TwitterShareButton>
                            </div>
                            <div class="share-icon icon-copy">
                              <div class="hover-text">
                                {/* <!-- Copy Text --> */}
                                <div className="copy-text d-flex">
                                  <input
                                    value={`${currentUrl}/${
                                      moments.life.verified_name ||
                                      moments.life.life_id
                                    }/${moments._id}`}
                                    disabled
                                    type="text"
                                    ref={textRef}
                                  />
                                </div>
                              </div>
                              <img
                                src={images.CopyIcon}
                                onClick={() =>
                                  copyToClipboard(moments._id, moments)
                                }
                                className="icon"
                              ></img>
                            </div>
                          </div>
                        )}
                      </a>
                    </div>
                  </div>
                )}

                {moments.images.length > 0 &&
                  (moments.images[0]?.media_type === "image" ||
                  moments.images[0]?.media_type == null ? (
                    <>
                      <Link
                        to={`/read/${
                          moments?.life?.verified_name || moments?.life?.life_id
                        }/${moments._id}`}
                        target="_blank"
                      >
                        <img
                          className="moment-img"
                          src={moments.images[0].url}
                        />
                        <div className="overlay"></div>
                      </Link>
                      
                    </>
                  ) : (
                    <div>
                      <VideoPlayer
                        className="videoFrame"
                        source={moments.images[0].url}
                      />
                    </div>
                  ))}

                <div className="title">
                  <Link
                    to={`/read/${
                      moments?.life?.verified_name || moments?.life?.life_id
                    }/${moments._id}`}
                    target="_blank"
                  >
                    <div className="p">
                      <div className="life-logo-name">
                        <img
                          src={moments.life?.life_logo}
                          className="img"
                        ></img>
                        {moments.images.length < 1 ? (
                          <div
                            className="moment-date"
                            style={{ color: "black" }}
                          >
                            {moments.moment_date}
                          </div>
                        ) : (
                          <div className="moment-date">
                            {moments.moment_date}
                          </div>
                        )}
                      </div>
                      {moments.images.length < 1 ? (
                        <div
                          className="large-des"
                          style={{ height: "fit-content" }}
                        >
                          {moments.moment_title.length > 150
                            ? moments.moment_title.slice(0, 150) + "..."
                            : moments.moment_title}
                        </div>
                      ) : (
                        <div className="moment-title">
                          {moments.moment_title.length > 150
                            ? moments.moment_title.slice(0, 150) + "..."
                            : moments.moment_title}
                        </div>
                      )}

                      <div>
                        {moments.images.length < 1 && index === 0 ? (
                          <div className="para" style={{ padding: "0px" }}>
                            <div>
                              {moments.moment_details.length > maxLargeImage
                                ? moments.moment_details.slice(
                                    0,
                                    maxLargeImage
                                  ) + "..."
                                : moments.moment_details}
                            </div>
                          </div>
                        ) : (
                          moments.images.length < 1 && (
                            <div className="para">
                              <div>
                                {moments.moment_details.length > maxLargeImage
                                  ? moments.moment_details.slice(
                                      0,
                                      maxLargeImage
                                    ) + "..."
                                  : moments.moment_details}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </Link>

                  <div
                    className="reaction-icons-large"
                    style={{
                      color: moments.images.length < 1 ? "#A8A8A8" : "white",
                    }}
                  >
                    <OverlayTrigger
                      key="left"
                      placement="left"
                      overlay={<Tooltip>Like</Tooltip>}
                    >
                      <div
                        className="mt-2"
                        onClick={() => handleShow(moments._id)}
                      >
                        <i class="bi bi-hand-thumbs-up"></i>
                      </div>
                    </OverlayTrigger>
                    {moments.commentable === true && (
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Comment</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShow(moments._id)}
                        >
                          <i class="bi bi-chat-text"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                    {moments.shareable === true && (
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Share</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShareClick(moments._id)}
                        >
                          <i class="bi bi-send-fill"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>

                {moments.images.length < 1 && index === 0 && (
                  <div className="tile-footer">
                    <div className="reaction-icons d-flex gap-4">
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Like</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleCommentModal(moments._id)}
                        >
                          <i class="bi bi-hand-thumbs-up"></i>
                        </div>
                      </OverlayTrigger>
                      {moments.commentable === true && (
                        <OverlayTrigger
                          key="left"
                          placement="left"
                          overlay={<Tooltip>Comment</Tooltip>}
                        >
                          <div
                            className="mt-2"
                            onClick={() => handleCommentModal(moments._id)}
                          >
                            <i class="bi bi-chat-text"></i>
                          </div>
                        </OverlayTrigger>
                      )}
                      {moments.shareable === true && (
                        <OverlayTrigger
                          key="left"
                          placement="left"
                          overlay={<Tooltip>Share</Tooltip>}
                        >
                          <div
                            className="mt-2"
                            onClick={() => handleShareClick(moments._id)}
                          >
                            <i class="bi bi-send-fill"></i>
                          </div>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                )}
                <div className="tile-footer">
                  <div className="reaction-icons d-flex gap-4">
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip>Like</Tooltip>}
                    >
                      <div
                        className="mt-2"
                        onClick={() => handleCommentModal(moments._id)}
                      >
                        <i class="bi bi-hand-thumbs-up"></i>
                      </div>
                    </OverlayTrigger>
                    {moments.commentable === true && (
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip>Comment</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleCommentModal(moments._id)}
                        >
                          <i class="bi bi-chat-text"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                    {moments.shareable === true && (
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip>Share</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShareClick(moments._id)}
                        >
                          <i class="bi bi-send-fill"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              </div>
            )
          : momentArray.length === 3
          ? postArray.push(
              <div
                key={moments._id}
                className={`tile ${index === 2 ? "large" : ""}`}
              >
                {userLogin ? (
                  <Dropdown
                    onToggle={(isOpen) => handleDropDown(isOpen, moments.life)}
                  >
                    <Dropdown.Toggle id="dropdown-basic">
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>More</Tooltip>}
                      >
                        <img src={images.MoofIcon} className="moof-icon"></img>
                      </OverlayTrigger>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {loading ? (
                        <Spinner
                          animation="border"
                          variant="dark"
                          style={{
                            width: "15px",
                            height: "15px",
                            textAlign: "center",
                            margin: "auto",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        />
                      ) : !permissionGranted ? (
                        <Dropdown.Item
                          onClick={() => showReportModal(moments._id)}
                        >
                          Report Moment
                        </Dropdown.Item>
                      ) : (
                        <>
                          <Dropdown.Item
                            onClick={() => updateMoment(moments._id)}
                          >
                            Update Moment
                          </Dropdown.Item>
                          <DropdownItem
                            // onClick={() => handleDeleteMoment(moment._id)}
                            onClick={() =>
                              handleConfirmationPopupShow(moments._id)
                            }
                          >
                            Delete Moment
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              handleLinkMomentPopupOpen(moments._id)
                            }
                          >
                            Connect to my Life
                          </DropdownItem>
                        </>
                      )}
                      {moments.images.length > 0 && (
                        <DropdownItem
                          onClick={() => handleShowModal(moments.images[0])}
                        >
                          Copyright Info
                        </DropdownItem>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <OverlayTrigger
                    key="left"
                    placement="left"
                    overlay={<Tooltip>More</Tooltip>}
                  >
                    <img
                      src={images.MoofIcon}
                      onClick={showPopupModal}
                      className="moof-icon"
                    ></img>
                  </OverlayTrigger>
                )}

                {tooltipState[moments._id] && (
                  <div className="copy-message ">Link copied</div>
                )}
                {moments.shareable === true && (
                  <div className="icons share-icon">
                    <div class="share-feed">
                      <a
                        class="share-btn"
                        onClick={() => handleShareClick(moments._id)}
                      >
                        {shareBoxVisible && shareMoment === moments._id && (
                          <div class="share-box" ref={shareBoxRef}>
                            <div class="share-icon">
                              <label>Telegram</label>
                              <TelegramShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                              >
                                <TelegramIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </TelegramShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Linkedin</label>
                              <LinkedinShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                                source="MoofLife"
                              >
                                <LinkedinIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </LinkedinShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Whatsapp</label>
                              <WhatsappShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                                separator=":: "
                              >
                                <WhatsappIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </WhatsappShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Facebook</label>
                              <FacebookShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                              >
                                <FacebookIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </FacebookShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Mask</label>
                              <TwitterShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                              >
                                <XIcon size={32} round className="icon" />
                              </TwitterShareButton>
                            </div>
                            <div class="share-icon icon-copy">
                              <div class="hover-text">
                                {/* <!-- Copy Text --> */}
                                <div className="copy-text d-flex">
                                  <input
                                    value={`${currentUrl}/${
                                      moments.life.verified_name ||
                                      moments.life.life_id
                                    }/${moments._id}`}
                                    disabled
                                    type="text"
                                    ref={textRef}
                                  />
                                </div>
                              </div>
                              <img
                                src={images.CopyIcon}
                                onClick={() =>
                                  copyToClipboard(moments._id, moments)
                                }
                                className="icon"
                              ></img>
                            </div>
                          </div>
                        )}
                      </a>
                    </div>
                  </div>
                )}

                {moments.images.length > 0 &&
                  (moments.images[0]?.media_type === "image" ||
                  moments.images[0]?.media_type == null ? (
                    <>
                      <Link
                        to={`/read/${
                          moments?.life?.verified_name || moments?.life?.life_id
                        }/${moments._id}`}
                        target="_blank"
                      >
                        <img
                          className="moment-img"
                          src={moments.images[0].url}
                        />
                      <div className="overlay"></div>
                      </Link>
                    </>
                  ) : (
                    <div>
                      <VideoPlayer
                        className="videoFrame"
                        source={moments.images[0].url}
                        style={{ height: "500px !important" }}
                      />
                    </div>
                  ))}

                <div className="title">
                  <Link
                    to={`/read/${
                      moments?.life?.verified_name || moments?.life?.life_id
                    }/${moments._id}`}
                    target="_blank"
                  >
                    <div className="p">
                      <div className="life-logo-name">
                        <img
                          src={moments.life?.life_logo}
                          className="img"
                        ></img>
                        {moments.images.length < 1 ? (
                          <div
                            className="moment-date"
                            style={{ color: "black" }}
                          >
                            {moments.moment_date}
                          </div>
                        ) : (
                          <div className="moment-date">
                            {moments.moment_date}
                          </div>
                        )}
                      </div>
                      {index === 2 && moments.images.length < 1 ? (
                        <div className="large-des">
                          {moments.moment_title}
                        </div>
                      ) : (
                        <div className="moment-title">
                          {moments.moment_title}
                        </div>
                      )}
                      <div>
                        {moments.images.length < 1 && index === 2 ? (
                          <div className="para p-0">
                            {moments.moment_details.length > maxLargeImage
                              ? moments.moment_details.slice(0, maxLargeImage) +
                                "..."
                              : moments.moment_details}
                          </div>
                        ) : (
                          moments.images.length === 0 && (
                            <div className="para p-0">
                              {moments.moment_details.length > maxLength
                                ? moments.moment_details.slice(0, maxLength) +
                                  "..."
                                : moments.moment_details}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </Link>

                  <div
                    className="reaction-icons-large"
                    style={{
                      color:
                        index === 2 && moments.images.length < 1
                          ? "#A8A8A8"
                          : "white",
                    }}
                  >
                    <OverlayTrigger
                      key="left"
                      placement="left"
                      overlay={<Tooltip>Like</Tooltip>}
                    >
                      <div
                        className="mt-2"
                        onClick={() => handleShow(moments._id)}
                      >
                        <i class="bi bi-hand-thumbs-up"></i>
                      </div>
                    </OverlayTrigger>
                    {moments.commentable === true && (
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Comment</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShow(moments._id)}
                        >
                          <i class="bi bi-chat-text"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                    {moments.shareable === true && (
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Share</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShareClick(moments._id)}
                        >
                          <i class="bi bi-send-fill"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>

                {moments.images.length < 1 && index === 0 && (
                  <div className="tile-footer">
                    <div className="reaction-icons d-flex gap-4">
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Like</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleCommentModal(moments._id)}
                        >
                          <i class="bi bi-hand-thumbs-up"></i>
                        </div>
                      </OverlayTrigger>
                      {moments.commentable === true && (
                        <OverlayTrigger
                          key="left"
                          placement="left"
                          overlay={<Tooltip>Comment</Tooltip>}
                        >
                          <div
                            className="mt-2"
                            onClick={() => handleCommentModal(moments._id)}
                          >
                            <i class="bi bi-chat-text"></i>
                          </div>
                        </OverlayTrigger>
                      )}
                      {moments.shareable === true && (
                        <OverlayTrigger
                          key="left"
                          placement="left"
                          overlay={<Tooltip>Share</Tooltip>}
                        >
                          <div
                            className="mt-2"
                            onClick={() => handleShareClick(moments._id)}
                          >
                            <i class="bi bi-send-fill"></i>
                          </div>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                )}
                <div className="tile-footer">
                  <div className="reaction-icons d-flex gap-4">
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip>Like</Tooltip>}
                    >
                      <div
                        className="mt-2"
                        onClick={() => handleCommentModal(moments._id)}
                      >
                        <i class="bi bi-hand-thumbs-up"></i>
                      </div>
                    </OverlayTrigger>
                    {moments.commentable === true && (
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip>Comment</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleCommentModal(moments._id)}
                        >
                          <i class="bi bi-chat-text"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                    {moments.shareable === true && (
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip>Share</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShareClick(moments._id)}
                        >
                          <i class="bi bi-send-fill"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              </div>
            )
          : momentArray.length === 4
          ? postArray.push(
              <div
                key={moments._id}
                className={`tile ${index === 2 ? "" : ""}`}
              >
                {userLogin ? (
                  <Dropdown
                    onToggle={(isOpen) => handleDropDown(isOpen, moments.life)}
                  >
                    <Dropdown.Toggle id="dropdown-basic">
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>More</Tooltip>}
                      >
                        <img src={images.MoofIcon} className="moof-icon"></img>
                      </OverlayTrigger>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {loading ? (
                        <Spinner
                          animation="border"
                          variant="dark"
                          style={{
                            width: "15px",
                            height: "15px",
                            textAlign: "center",
                            margin: "auto",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        />
                      ) : !permissionGranted ? (
                        <Dropdown.Item
                          onClick={() => showReportModal(moments._id)}
                        >
                          Report Moment
                        </Dropdown.Item>
                      ) : (
                        <>
                          <Dropdown.Item
                            onClick={() => updateMoment(moments._id)}
                          >
                            Update Moment
                          </Dropdown.Item>
                          <DropdownItem
                            // onClick={() => handleDeleteMoment(moment._id)}
                            onClick={() =>
                              handleConfirmationPopupShow(moments._id)
                            }
                          >
                            Delete Moment
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              handleLinkMomentPopupOpen(moments._id)
                            }
                          >
                            Connect to my Life
                          </DropdownItem>
                        </>
                      )}
                      {moments.images.length > 0 && (
                        <DropdownItem
                          onClick={() => handleShowModal(moments.images[0])}
                        >
                          Copyright Info
                        </DropdownItem>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <OverlayTrigger
                    key="left"
                    placement="left"
                    overlay={<Tooltip>More</Tooltip>}
                  >
                    <img
                      src={images.MoofIcon}
                      onClick={showPopupModal}
                      className="moof-icon"
                    ></img>
                  </OverlayTrigger>
                )}

                {tooltipState[moments._id] && (
                  <div className="copy-message ">Link copied</div>
                )}
                {moments.shareable === true && (
                  <div className="icons share-icon">
                    <div class="share-feed">
                      <a
                        class="share-btn"
                        onClick={() => handleShareClick(moments._id)}
                      >
                        {shareBoxVisible && shareMoment === moments._id && (
                          <div class="share-box" ref={shareBoxRef}>
                            <div class="share-icon">
                              <label>Telegram</label>
                              <TelegramShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                              >
                                <TelegramIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </TelegramShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Linkedin</label>
                              <LinkedinShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                                source="MoofLife"
                              >
                                <LinkedinIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </LinkedinShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Whatsapp</label>
                              <WhatsappShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                                separator=":: "
                              >
                                <WhatsappIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </WhatsappShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Facebook</label>
                              <FacebookShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                              >
                                <FacebookIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </FacebookShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Mask</label>
                              <TwitterShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                              >
                                <XIcon size={32} round className="icon" />
                              </TwitterShareButton>
                            </div>
                            <div class="share-icon icon-copy">
                              <div class="hover-text">
                                {/* <!-- Copy Text --> */}
                                <div className="copy-text d-flex">
                                  <input
                                    value={`${currentUrl}/${
                                      moments.life.verified_name ||
                                      moments.life.life_id
                                    }/${moments._id}`}
                                    disabled
                                    type="text"
                                    ref={textRef}
                                  />
                                </div>
                              </div>
                              <img
                                src={images.CopyIcon}
                                onClick={() =>
                                  copyToClipboard(moments._id, moments)
                                }
                                className="icon"
                              ></img>
                            </div>
                          </div>
                        )}
                      </a>
                    </div>
                  </div>
                )}

                {moments.images.length > 0 &&
                  (moments.images[0]?.media_type === "image" ||
                  moments.images[0]?.media_type == null ? (
                    <>
                      <Link
                        to={`/read/${
                          moments?.life?.verified_name || moments?.life?.life_id
                        }/${moments._id}`}
                        target="_blank"
                      >
                        <img
                          className="moment-img"
                          src={moments.images[0].url}
                        />
                      <div className="overlay"></div>
                      </Link>
                    </>
                  ) : (
                    <div>
                      <VideoPlayer
                        className="videoFrame"
                        source={moments.images[0].url}
                      />
                    </div>
                  ))}

                <div className="title">
                  <Link
                    to={`/read/${
                      moments?.life?.verified_name || moments?.life?.life_id
                    }/${moments._id}`}
                    target="_blank"
                  >
                    <div className="p">
                      <div className="life-logo-name">
                        <img
                          src={moments.life?.life_logo}
                          className="img"
                        ></img>
                        {index === 0 && moments.images.length < 1 ? (
                          <div
                            className="moment-date"
                            style={{ color: "black" }}
                          >
                            {moments.moment_date}
                          </div>
                        ) : (
                          <div className="moment-date">
                            {moments.moment_date}
                          </div>
                        )}
                      </div>
                      {
                        <div className="large-des p-0 pb-1">
                          {moments.moment_title.length > 50
                            ? moments.moment_title.slice(0, 50) + "..."
                            : moments.moment_title}
                        </div>
                      }

                      <div>
                        {moments.images.length < 1 && (
                          <div className="para p-0">
                            <div>
                              {moments.moment_details.length > maxLength
                                ? moments.moment_details.slice(0, maxLength) +
                                  "..."
                                : moments.moment_details}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Link>

                  <div
                    className="reaction-icons-large"
                    style={{
                      color:
                        index === 0 && moments.images.length < 1
                          ? "#A8A8A8"
                          : "white",
                    }}
                  >
                    <OverlayTrigger
                      key="left"
                      placement="left"
                      overlay={<Tooltip>Like</Tooltip>}
                    >
                      <div
                        className="mt-2"
                        onClick={() => handleShow(moments._id)}
                      >
                        <i class="bi bi-hand-thumbs-up"></i>
                      </div>
                    </OverlayTrigger>
                    {moments.commentable === true && (
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Comment</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShow(moments._id)}
                        >
                          <i class="bi bi-chat-text"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                    {moments.shareable === true && (
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Share</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShareClick(moments._id)}
                        >
                          <i class="bi bi-send-fill"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>

                {moments.images.length < 1 && index === 0 && (
                  <div className="tile-footer">
                    <div className="reaction-icons d-flex gap-4">
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Like</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleCommentModal(moments._id)}
                        >
                          <i class="bi bi-hand-thumbs-up"></i>
                        </div>
                      </OverlayTrigger>
                      {moments.commentable === true && (
                        <OverlayTrigger
                          key="left"
                          placement="left"
                          overlay={<Tooltip>Comment</Tooltip>}
                        >
                          <div
                            className="mt-2"
                            onClick={() => handleCommentModal(moments._id)}
                          >
                            <i class="bi bi-chat-text"></i>
                          </div>
                        </OverlayTrigger>
                      )}
                      {moments.shareable === true && (
                        <OverlayTrigger
                          key="left"
                          placement="left"
                          overlay={<Tooltip>Share</Tooltip>}
                        >
                          <div
                            className="mt-2"
                            onClick={() => handleShareClick(moments._id)}
                          >
                            <i class="bi bi-send-fill"></i>
                          </div>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                )}
                <div className="tile-footer">
                  <div className="reaction-icons d-flex gap-4">
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip>Like</Tooltip>}
                    >
                      <div
                        className="mt-2"
                        onClick={() => handleCommentModal(moments._id)}
                      >
                        <i class="bi bi-hand-thumbs-up"></i>
                      </div>
                    </OverlayTrigger>
                    {moments.commentable === true && (
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip>Comment</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleCommentModal(moments._id)}
                        >
                          <i class="bi bi-chat-text"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                    {moments.shareable === true && (
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip>Share</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShareClick(moments._id)}
                        >
                          <i class="bi bi-send-fill"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              </div>
            )
          : momentArray.length === 5
          ? postArray.push(
              <div
                key={moments._id}
                className={`tile ${
                  index === 1 || index === 3 || index === 4 ? "large" : ""
                }`}
              >
                {userLogin ? (
                  <Dropdown
                    onToggle={(isOpen) => handleDropDown(isOpen, moments.life)}
                  >
                    <Dropdown.Toggle id="dropdown-basic">
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>More</Tooltip>}
                      >
                        <img src={images.MoofIcon} className="moof-icon"></img>
                      </OverlayTrigger>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {loading ? (
                        <Spinner
                          animation="border"
                          variant="dark"
                          style={{
                            width: "15px",
                            height: "15px",
                            textAlign: "center",
                            margin: "auto",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        />
                      ) : !permissionGranted ? (
                        <Dropdown.Item
                          onClick={() => showReportModal(moments._id)}
                        >
                          Report Moment
                        </Dropdown.Item>
                      ) : (
                        <>
                          <Dropdown.Item
                            onClick={() => updateMoment(moments._id)}
                          >
                            Update Moment
                          </Dropdown.Item>
                          <DropdownItem
                            // onClick={() => handleDeleteMoment(moment._id)}
                            onClick={() =>
                              handleConfirmationPopupShow(moments._id)
                            }
                          >
                            Delete Moment
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              handleLinkMomentPopupOpen(moments._id)
                            }
                          >
                            Connect to my Life
                          </DropdownItem>
                        </>
                      )}
                      {moments.images.length > 0 && (
                        <DropdownItem
                          onClick={() => handleShowModal(moments.images[0])}
                        >
                          Copyright Info
                        </DropdownItem>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <OverlayTrigger
                    key="left"
                    placement="left"
                    overlay={<Tooltip>More</Tooltip>}
                  >
                    <img
                      src={images.MoofIcon}
                      onClick={showPopupModal}
                      className="moof-icon"
                    ></img>
                  </OverlayTrigger>
                )}

                {tooltipState[moments._id] && (
                  <div className="copy-message ">Link copied</div>
                )}
                {moments.shareable === true && (
                  <div className="icons share-icon">
                    <div class="share-feed">
                      <a
                        class="share-btn"
                        onClick={() => handleShareClick(moments._id)}
                      >
                        {shareBoxVisible && shareMoment === moments._id && (
                          <div class="share-box" ref={shareBoxRef}>
                            <div class="share-icon">
                              <label>Telegram</label>
                              <TelegramShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                              >
                                <TelegramIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </TelegramShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Linkedin</label>
                              <LinkedinShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                                source="MoofLife"
                              >
                                <LinkedinIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </LinkedinShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Whatsapp</label>
                              <WhatsappShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                                separator=":: "
                              >
                                <WhatsappIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </WhatsappShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Facebook</label>
                              <FacebookShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                              >
                                <FacebookIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </FacebookShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Mask</label>
                              <TwitterShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                              >
                                <XIcon size={32} round className="icon" />
                              </TwitterShareButton>
                            </div>
                            <div class="share-icon icon-copy">
                              <div class="hover-text">
                                {/* <!-- Copy Text --> */}
                                <div className="copy-text d-flex">
                                  <input
                                    value={`${currentUrl}/${
                                      moments.life.verified_name ||
                                      moments.life.life_id
                                    }/${moments._id}`}
                                    disabled
                                    type="text"
                                    ref={textRef}
                                  />
                                </div>
                              </div>
                              <img
                                src={images.CopyIcon}
                                onClick={() =>
                                  copyToClipboard(moments._id, moments)
                                }
                                className="icon"
                              ></img>
                            </div>
                          </div>
                        )}
                      </a>
                    </div>
                  </div>
                )}

                {moments.images.length > 0 &&
                  (moments.images[0]?.media_type === "image" ||
                  moments.images[0]?.media_type == null ? (
                    <>
                      <Link
                        to={`/read/${
                          moments?.life?.verified_name || moments?.life?.life_id
                        }/${moments._id}`}
                        target="_blank"
                      >
                        <img
                          className="moment-img"
                          src={moments.images[0].url}
                        />
                      <div className="overlay"></div>
                      </Link>
                    </>
                  ) : (
                    <div>
                      <VideoPlayer
                        className="videoFrame"
                        source={moments.images[0].url}
                      />
                    </div>
                  ))}

                <div className="title">
                  <Link
                    to={`/read/${
                      moments?.life?.verified_name || moments?.life?.life_id
                    }/${moments._id}`}
                    target="_blank"
                  >
                    <div className="p">
                      <div className="life-logo-name">
                        <img
                          src={moments.life?.life_logo}
                          className="img"
                        ></img>
                        {(index === 1 || index === 3 || index === 4) &&
                        moments.images.length < 1 ? (
                          <div
                            className="moment-date"
                            style={{ color: "black" }}
                          >
                            {moments.moment_date}
                          </div>
                        ) : (
                          <div className="moment-date">
                            {moments.moment_date}
                          </div>
                        )}
                      </div>
                      {index === 1 || index === 3 || index === 4 ? (
                        <div
                          className={
                            moments.images.length === 0
                              ? "large-des"
                              : "moment-title"
                          }
                        >
                          {moments.moment_title.length > 150
                            ? moments.moment_title.slice(0, 150) + "..."
                            : moments.moment_title}
                        </div>
                      ) : (
                        <div className="moment-title">
                          {moments.moment_title.length > 50
                            ? moments.moment_title.slice(0, 50) + "..."
                            : moments.moment_title}
                        </div>
                      )}

                      <div>
                        {moments.images.length < 1 &&
                        (index === 1 || index === 3 || index === 4) ? (
                          <div className="para p-0">
                            <div>
                              {moments.moment_details.length > maxLargeImage
                                ? moments.moment_details.slice(
                                    0,
                                    maxLargeImage
                                  ) + "..."
                                : moments.moment_details}
                            </div>
                          </div>
                        ) : (
                          moments.images.length < 1 && (
                            <div className="para p-0">
                              <div>
                                {moments.moment_details.length > maxLength
                                  ? moments.moment_details.slice(0, maxLength) +
                                    "..."
                                  : moments.moment_details}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </Link>

                  <div
                    className="reaction-icons-large"
                    style={{
                      color:
                        (index === 1 || index === 3 || index === 4) &&
                        moments.images.length < 1
                          ? "#A8A8A8"
                          : "white",
                    }}
                  >
                    <OverlayTrigger
                      key="left"
                      placement="left"
                      overlay={<Tooltip>Like</Tooltip>}
                    >
                      <div
                        className="mt-2"
                        onClick={() => handleShow(moments._id)}
                      >
                        <i class="bi bi-hand-thumbs-up"></i>
                      </div>
                    </OverlayTrigger>
                    {moments.commentable === true && (
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Comment</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShow(moments._id)}
                        >
                          <i class="bi bi-chat-text"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                    {moments.shareable === true && (
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Share</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShareClick(moments._id)}
                        >
                          <i class="bi bi-send-fill"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>

                {moments.images.length < 1 && index === 0 && (
                  <div className="tile-footer">
                    <div className="reaction-icons d-flex gap-4">
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Like</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleCommentModal(moments._id)}
                        >
                          <i class="bi bi-hand-thumbs-up"></i>
                        </div>
                      </OverlayTrigger>
                      {moments.commentable === true && (
                        <OverlayTrigger
                          key="left"
                          placement="left"
                          overlay={<Tooltip>Comment</Tooltip>}
                        >
                          <div
                            className="mt-2"
                            onClick={() => handleCommentModal(moments._id)}
                          >
                            <i class="bi bi-chat-text"></i>
                          </div>
                        </OverlayTrigger>
                      )}
                      {moments.shareable === true && (
                        <OverlayTrigger
                          key="left"
                          placement="left"
                          overlay={<Tooltip>Share</Tooltip>}
                        >
                          <div
                            className="mt-2"
                            onClick={() => handleShareClick(moments._id)}
                          >
                            <i class="bi bi-send-fill"></i>
                          </div>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                )}
                <div className="tile-footer">
                  <div className="reaction-icons d-flex gap-4">
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip>Like</Tooltip>}
                    >
                      <div
                        className="mt-2"
                        onClick={() => handleCommentModal(moments._id)}
                      >
                        <i class="bi bi-hand-thumbs-up"></i>
                      </div>
                    </OverlayTrigger>
                    {moments.commentable === true && (
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip>Comment</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleCommentModal(moments._id)}
                        >
                          <i class="bi bi-chat-text"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                    {moments.shareable === true && (
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip>Share</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShareClick(moments._id)}
                        >
                          <i class="bi bi-send-fill"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              </div>
            )
          : momentArray.length === 6
          ? postArray.push(
              <div
                key={moments._id}
                className={`tile ${index === 2 || index === 3 ? "large" : ""}`}
              >
                {userLogin ? (
                  <Dropdown
                    onToggle={(isOpen) => handleDropDown(isOpen, moments.life)}
                  >
                    <Dropdown.Toggle id="dropdown-basic">
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>More</Tooltip>}
                      >
                        <img src={images.MoofIcon} className="moof-icon"></img>
                      </OverlayTrigger>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {loading ? (
                        <Spinner
                          animation="border"
                          variant="dark"
                          style={{
                            width: "15px",
                            height: "15px",
                            textAlign: "center",
                            margin: "auto",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        />
                      ) : !permissionGranted ? (
                        <Dropdown.Item
                          onClick={() => showReportModal(moments._id)}
                        >
                          Report Moment
                        </Dropdown.Item>
                      ) : (
                        <>
                          <Dropdown.Item
                            onClick={() => updateMoment(moments._id)}
                          >
                            Update Moment
                          </Dropdown.Item>
                          <DropdownItem
                            // onClick={() => handleDeleteMoment(moment._id)}
                            onClick={() =>
                              handleConfirmationPopupShow(moments._id)
                            }
                          >
                            Delete Moment
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              handleLinkMomentPopupOpen(moments._id)
                            }
                          >
                            Connect to my Life
                          </DropdownItem>
                        </>
                      )}
                      {moments.images.length > 0 && (
                        <DropdownItem
                          onClick={() => handleShowModal(moments.images[0])}
                        >
                          Copyright Info
                        </DropdownItem>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <OverlayTrigger
                    key="left"
                    placement="left"
                    overlay={<Tooltip>More</Tooltip>}
                  >
                    <img
                      src={images.MoofIcon}
                      onClick={showPopupModal}
                      className="moof-icon"
                    ></img>
                  </OverlayTrigger>
                )}

                {tooltipState[moments._id] && (
                  <div className="copy-message ">Link copied</div>
                )}
                {moments.shareable === true && (
                  <div className="icons share-icon">
                    <div class="share-feed">
                      <a
                        class="share-btn"
                        onClick={() => handleShareClick(moments._id)}
                      >
                        {shareBoxVisible && shareMoment === moments._id && (
                          <div class="share-box" ref={shareBoxRef}>
                            <div class="share-icon">
                              <label>Telegram</label>
                              <TelegramShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                              >
                                <TelegramIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </TelegramShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Linkedin</label>
                              <LinkedinShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                                source="MoofLife"
                              >
                                <LinkedinIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </LinkedinShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Whatsapp</label>
                              <WhatsappShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                                separator=":: "
                              >
                                <WhatsappIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </WhatsappShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Facebook</label>
                              <FacebookShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                              >
                                <FacebookIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </FacebookShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Mask</label>
                              <TwitterShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                              >
                                <XIcon size={32} round className="icon" />
                              </TwitterShareButton>
                            </div>
                            <div class="share-icon icon-copy">
                              <div class="hover-text">
                                {/* <!-- Copy Text --> */}
                                <div className="copy-text d-flex">
                                  <input
                                    value={`${currentUrl}/${
                                      moments.life.verified_name ||
                                      moments.life.life_id
                                    }/${moments._id}`}
                                    disabled
                                    type="text"
                                    ref={textRef}
                                  />
                                </div>
                              </div>
                              <img
                                src={images.CopyIcon}
                                onClick={() =>
                                  copyToClipboard(moments._id, moments)
                                }
                                className="icon"
                              ></img>
                            </div>
                          </div>
                        )}
                      </a>
                    </div>
                  </div>
                )}

                {moments.images.length > 0 &&
                  (moments.images[0]?.media_type === "image" ||
                  moments.images[0]?.media_type == null ? (
                    <>
                      <Link
                        to={`/read/${
                          moments?.life?.verified_name || moments?.life?.life_id
                        }/${moments._id}`}
                        target="_blank"
                      >
                        <img
                          className="moment-img"
                          src={moments.images[0].url}
                        />
                      <div className="overlay"></div>
                      </Link>
                    </>
                  ) : (
                    <div>
                      <VideoPlayer
                        className="videoFrame"
                        source={moments.images[0].url}
                      />
                    </div>
                  ))}

                <div className="title">
                  <Link
                    to={`/read/${
                      moments?.life?.verified_name || moments?.life?.life_id
                    }/${moments._id}`}
                    target="_blank"
                  >
                    <div className="p">
                      <div className="life-logo-name">
                        <img
                          src={moments.life?.life_logo}
                          className="img"
                        ></img>
                        {(index === 2 || index === 3) &&
                        moments.images.length < 1 ? (
                          <div
                            className="moment-date"
                            style={{ color: "black" }}
                          >
                            {moments.moment_date}
                          </div>
                        ) : (
                          <div className="moment-date">
                            {moments.moment_date}
                          </div>
                        )}
                      </div>
                      {(index === 2 || index === 3) &&
                      moments.images.length < 1 ? (
                        <div className="large-des">
                          {moments.moment_title.length > 150
                            ? moments.moment_title.slice(0, 150) + "..."
                            : moments.moment_title}
                        </div>
                      ) : (
                        <div className="moment-title">
                          {moments.moment_title.length > 50
                            ? moments.moment_title.slice(0, 50) + "..."
                            : moments.moment_title}
                        </div>
                      )}

                      {moments.images.length < 1 &&
                      (index === 2 || index === 3) ? (
                        <div className="para p-0">
                          <div>
                            {moments.moment_details.length > maxLargeImage
                              ? moments.moment_details.slice(0, maxLargeImage) +
                                "..."
                              : moments.moment_details}
                          </div>
                        </div>
                      ) : (
                        moments.images.length < 1 && (
                          <div className="para p-0">
                            <div>
                              {moments.moment_details.length > maxLength
                                ? moments.moment_details.slice(0, maxLength) +
                                  "..."
                                : moments.moment_details}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </Link>

                  <div
                    className="reaction-icons-large"
                    style={{
                      color:
                        index === 0 && moments.images.length < 1
                          ? "#A8A8A8"
                          : "white",
                    }}
                  >
                    <OverlayTrigger
                      key="left"
                      placement="left"
                      overlay={<Tooltip>Like</Tooltip>}
                    >
                      <div
                        className="mt-2"
                        onClick={() => handleShow(moments._id)}
                      >
                        <i class="bi bi-hand-thumbs-up"></i>
                      </div>
                    </OverlayTrigger>
                    {moments.commentable === true && (
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Comment</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShow(moments._id)}
                        >
                          <i class="bi bi-chat-text"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                    {moments.shareable === true && (
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Share</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShareClick(moments._id)}
                        >
                          <i class="bi bi-send-fill"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>

                {moments.images.length < 1 && index === 0 && (
                  <div className="tile-footer">
                    <div className="reaction-icons d-flex gap-4">
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Like</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleCommentModal(moments._id)}
                        >
                          <i class="bi bi-hand-thumbs-up"></i>
                        </div>
                      </OverlayTrigger>
                      {moments.commentable === true && (
                        <OverlayTrigger
                          key="left"
                          placement="left"
                          overlay={<Tooltip>Comment</Tooltip>}
                        >
                          <div
                            className="mt-2"
                            onClick={() => handleCommentModal(moments._id)}
                          >
                            <i class="bi bi-chat-text"></i>
                          </div>
                        </OverlayTrigger>
                      )}
                      {moments.shareable === true && (
                        <OverlayTrigger
                          key="left"
                          placement="left"
                          overlay={<Tooltip>Share</Tooltip>}
                        >
                          <div
                            className="mt-2"
                            onClick={() => handleShareClick(moments._id)}
                          >
                            <i class="bi bi-send-fill"></i>
                          </div>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                )}
                <div className="tile-footer">
                  <div className="reaction-icons d-flex gap-4">
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip>Like</Tooltip>}
                    >
                      <div
                        className="mt-2"
                        onClick={() => handleCommentModal(moments._id)}
                      >
                        <i class="bi bi-hand-thumbs-up"></i>
                      </div>
                    </OverlayTrigger>
                    {moments.commentable === true && (
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip>Comment</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleCommentModal(moments._id)}
                        >
                          <i class="bi bi-chat-text"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                    {moments.shareable === true && (
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip>Share</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShareClick(moments._id)}
                        >
                          <i class="bi bi-send-fill"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              </div>
            )
          : momentArray.length === 7
          ? postArray.push(
              <div
                key={moments._id}
                className={`tile ${index === 6 ? "large" : ""}`}
              >
                {userLogin ? (
                  <Dropdown
                    onToggle={(isOpen) => handleDropDown(isOpen, moments.life)}
                  >
                    <Dropdown.Toggle id="dropdown-basic">
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>More</Tooltip>}
                      >
                        <img src={images.MoofIcon} className="moof-icon"></img>
                      </OverlayTrigger>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {loading ? (
                        <Spinner
                          animation="border"
                          variant="dark"
                          style={{
                            width: "15px",
                            height: "15px",
                            textAlign: "center",
                            margin: "auto",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        />
                      ) : !permissionGranted ? (
                        <Dropdown.Item
                          onClick={() => showReportModal(moments._id)}
                        >
                          Report Moment
                        </Dropdown.Item>
                      ) : (
                        <>
                          <Dropdown.Item
                            onClick={() => updateMoment(moments._id)}
                          >
                            Update Moment
                          </Dropdown.Item>
                          <DropdownItem
                            // onClick={() => handleDeleteMoment(moment._id)}
                            onClick={() =>
                              handleConfirmationPopupShow(moments._id)
                            }
                          >
                            Delete Moment
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              handleLinkMomentPopupOpen(moments._id)
                            }
                          >
                            Connect to my Life
                          </DropdownItem>
                        </>
                      )}
                      {moments.images.length > 0 && (
                        <DropdownItem
                          onClick={() => handleShowModal(moments.images[0])}
                        >
                          Copyright Info
                        </DropdownItem>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <OverlayTrigger
                    key="left"
                    placement="left"
                    overlay={<Tooltip>More</Tooltip>}
                  >
                    <img
                      src={images.MoofIcon}
                      onClick={showPopupModal}
                      className="moof-icon"
                    ></img>
                  </OverlayTrigger>
                )}

                {tooltipState[moments._id] && (
                  <div className="copy-message ">Link copied</div>
                )}
                {moments.shareable === true && (
                  <div className="icons share-icon">
                    <div class="share-feed">
                      <a
                        class="share-btn"
                        onClick={() => handleShareClick(moments._id)}
                      >
                        {shareBoxVisible && shareMoment === moments._id && (
                          <div class="share-box" ref={shareBoxRef}>
                            <div class="share-icon">
                              <label>Telegram</label>
                              <TelegramShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                              >
                                <TelegramIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </TelegramShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Linkedin</label>
                              <LinkedinShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                                source="MoofLife"
                              >
                                <LinkedinIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </LinkedinShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Whatsapp</label>
                              <WhatsappShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                                separator=":: "
                              >
                                <WhatsappIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </WhatsappShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Facebook</label>
                              <FacebookShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                              >
                                <FacebookIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </FacebookShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Mask</label>
                              <TwitterShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                              >
                                <XIcon size={32} round className="icon" />
                              </TwitterShareButton>
                            </div>
                            <div class="share-icon icon-copy">
                              <div class="hover-text">
                                {/* <!-- Copy Text --> */}
                                <div className="copy-text d-flex">
                                  <input
                                    value={`${currentUrl}/${
                                      moments.life.verified_name ||
                                      moments.life.life_id
                                    }/${moments._id}`}
                                    disabled
                                    type="text"
                                    ref={textRef}
                                  />
                                </div>
                              </div>
                              <img
                                src={images.CopyIcon}
                                onClick={() =>
                                  copyToClipboard(moments._id, moments)
                                }
                                className="icon"
                              ></img>
                            </div>
                          </div>
                        )}
                      </a>
                    </div>
                  </div>
                )}

                {moments.images.length > 0 &&
                  (moments.images[0]?.media_type === "image" ||
                  moments.images[0]?.media_type == null ? (
                    <>
                      <Link
                        to={`/read/${
                          moments?.life?.verified_name || moments?.life?.life_id
                        }/${moments._id}`}
                        target="_blank"
                      >
                        <img
                          className="moment-img"
                          src={moments.images[0].url}
                        />
                      <div className="overlay"></div>
                      </Link>
                    </>
                  ) : (
                    <div>
                      <VideoPlayer
                        className="videoFrame"
                        source={moments.images[0].url}
                      />
                    </div>
                  ))}

                <div className="title">
                  <Link
                    to={`/read/${
                      moments?.life?.verified_name || moments?.life?.life_id
                    }/${moments._id}`}
                    target="_blank"
                  >
                    <div className="p">
                      <div className="life-logo-name">
                        <img
                          src={moments.life?.life_logo}
                          className="img"
                        ></img>
                        {index === 6 && moments.images.length < 1 ? (
                          <div
                            className="moment-date"
                            style={{ color: "black" }}
                          >
                            {moments.moment_date}
                          </div>
                        ) : (
                          <div className="moment-date">
                            {moments.moment_date}
                          </div>
                        )}
                      </div>
                      {moments.images.length < 1 && index === 6 ? (
                        <div className="large-des">
                          {moments.moment_title.length > 100
                            ? moments.moment_title.slice(0, 100) + "..."
                            : moments.moment_title}
                        </div>
                      ) : (
                        <div className="moment-title">
                          {moments.moment_title.length > 50
                            ? moments.moment_title.slice(0, 50) + "..."
                            : moments.moment_title}
                        </div>
                      )}

                      {moments.images.length < 1 && index === 6 ? (
                        <div className="para p-0">
                          <div>
                            {moments.moment_details.length > maxLargeImage
                              ? moments.moment_details.slice(0, maxLargeImage) +
                                "..."
                              : moments.moment_details}
                          </div>
                        </div>
                      ) : (
                        moments.images.length < 1 && (
                          <div className="para p-0">
                            <div>
                              {moments.moment_details.length > maxLength
                                ? moments.moment_details.slice(0, maxLength) +
                                  "..."
                                : moments.moment_details}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </Link>

                  <div
                    className="reaction-icons-large"
                    style={{
                      color:
                        index === 6 && moments.images.length < 1
                          ? "#A8A8A8"
                          : "white",
                    }}
                  >
                    <OverlayTrigger
                      key="left"
                      placement="left"
                      overlay={<Tooltip>Like</Tooltip>}
                    >
                      <div
                        className="mt-2"
                        onClick={() => handleShow(moments._id)}
                      >
                        <i class="bi bi-hand-thumbs-up"></i>
                      </div>
                    </OverlayTrigger>
                    {moments.commentable === true && (
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Comment</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShow(moments._id)}
                        >
                          <i class="bi bi-chat-text"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                    {moments.shareable === true && (
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Share</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShareClick(moments._id)}
                        >
                          <i class="bi bi-send-fill"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>

                {moments.images.length < 1 && index === 0 && (
                  <div className="tile-footer">
                    <div className="reaction-icons d-flex gap-4">
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Like</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleCommentModal(moments._id)}
                        >
                          <i class="bi bi-hand-thumbs-up" color="red"></i>
                        </div>
                      </OverlayTrigger>
                      {moments.commentable === true && (
                        <OverlayTrigger
                          key="left"
                          placement="left"
                          overlay={<Tooltip>Comment</Tooltip>}
                        >
                          <div
                            className="mt-2"
                            onClick={() => handleCommentModal(moments._id)}
                          >
                            <i class="bi bi-chat-text"></i>
                          </div>
                        </OverlayTrigger>
                      )}
                      {moments.shareable === true && (
                        <OverlayTrigger
                          key="left"
                          placement="left"
                          overlay={<Tooltip>Share</Tooltip>}
                        >
                          <div
                            className="mt-2"
                            onClick={() => handleShareClick(moments._id)}
                          >
                            <i class="bi bi-send-fill"></i>
                          </div>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                )}
                <div className="tile-footer">
                  <div className="reaction-icons d-flex gap-4">
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip>Like</Tooltip>}
                    >
                      <div
                        className="mt-2"
                        onClick={() => handleCommentModal(moments._id)}
                      >
                        <i class="bi bi-hand-thumbs-up"></i>
                      </div>
                    </OverlayTrigger>
                    {moments.commentable === true && (
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip>Comment</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleCommentModal(moments._id)}
                        >
                          <i class="bi bi-chat-text"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                    {moments.shareable === true && (
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip>Share</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShareClick(moments._id)}
                        >
                          <i class="bi bi-send-fill"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              </div>
            )
          : momentArray.length === 8 &&
            postArray.push(
              <div key={moments._id} className="tile">
                {userLogin ? (
                  <Dropdown
                    onToggle={(isOpen) => handleDropDown(isOpen, moments.life)}
                  >
                    <Dropdown.Toggle id="dropdown-basic">
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>More</Tooltip>}
                      >
                        <img src={images.MoofIcon} className="moof-icon"></img>
                      </OverlayTrigger>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {loading ? (
                        <Spinner
                          animation="border"
                          variant="dark"
                          style={{
                            width: "15px",
                            height: "15px",
                            textAlign: "center",
                            margin: "auto",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        />
                      ) : !permissionGranted ? (
                        <Dropdown.Item
                          onClick={() => showReportModal(moments._id)}
                        >
                          Report Moment
                        </Dropdown.Item>
                      ) : (
                        <>
                          <Dropdown.Item
                            onClick={() => updateMoment(moments._id)}
                          >
                            Update Moment
                          </Dropdown.Item>
                          <DropdownItem
                            // onClick={() => handleDeleteMoment(moment._id)}
                            onClick={() =>
                              handleConfirmationPopupShow(moments._id)
                            }
                          >
                            Delete Moment
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              handleLinkMomentPopupOpen(moments._id)
                            }
                          >
                            Connect to my Life
                          </DropdownItem>
                        </>
                      )}
                      {moments.images.length > 0 && (
                        <DropdownItem
                          onClick={() => handleShowModal(moments.images[0])}
                        >
                          Copyright Info
                        </DropdownItem>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <OverlayTrigger
                    key="left"
                    placement="left"
                    overlay={<Tooltip>More</Tooltip>}
                  >
                    <img
                      src={images.MoofIcon}
                      onClick={showPopupModal}
                      className="moof-icon"
                    ></img>
                  </OverlayTrigger>
                )}

                {tooltipState[moments._id] && (
                  <div className="copy-message ">Link copied</div>
                )}
                {moments.shareable === true && (
                  <div className="icons share-icon">
                    <div class="share-feed">
                      <a
                        class="share-btn"
                        onClick={() => handleShareClick(moments._id)}
                      >
                        {shareBoxVisible && shareMoment === moments._id && (
                          <div class="share-box" ref={shareBoxRef}>
                            <div class="share-icon">
                              <label>Telegram</label>
                              <TelegramShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                              >
                                <TelegramIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </TelegramShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Linkedin</label>
                              <LinkedinShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                                source="MoofLife"
                              >
                                <LinkedinIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </LinkedinShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Whatsapp</label>
                              <WhatsappShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                                separator=":: "
                              >
                                <WhatsappIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </WhatsappShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Facebook</label>
                              <FacebookShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                              >
                                <FacebookIcon
                                  size={32}
                                  round
                                  className="icon"
                                />
                              </FacebookShareButton>
                            </div>
                            <div class="share-icon">
                              <label>Mask</label>
                              <TwitterShareButton
                                url={`${currentUrl}/${
                                  moments.life.verified_name ||
                                  moments.life.life_id
                                }/${moments._id}`}
                                title={moments.moment_title}
                              >
                                <XIcon size={32} round className="icon" />
                              </TwitterShareButton>
                            </div>
                            <div class="share-icon icon-copy">
                              <div class="hover-text">
                                {/* <!-- Copy Text --> */}
                                <div className="copy-text d-flex">
                                  <input
                                    value={`${currentUrl}/${
                                      moments.life.verified_name ||
                                      moments.life.life_id
                                    }/${moments._id}`}
                                    disabled
                                    type="text"
                                    ref={textRef}
                                  />
                                </div>
                              </div>
                              <img
                                src={images.CopyIcon}
                                onClick={() =>
                                  copyToClipboard(moments._id, moments)
                                }
                                className="icon"
                              ></img>
                            </div>
                          </div>
                        )}
                      </a>
                    </div>
                  </div>
                )}

                {moments.images.length > 0 &&
                  (moments.images[0]?.media_type === "image" ||
                  moments.images[0]?.media_type == null ? (
                    <>
                      <Link
                        to={`/read/${
                          moments?.life?.verified_name || moments?.life?.life_id
                        }/${moments._id}`}
                        target="_blank"
                      >
                        <img
                          className="moment-img"
                          src={moments.images[0].url}
                        />
                      <div className="overlay"></div>
                      </Link>
                    </>
                  ) : (
                    <div>
                      <VideoPlayer
                        className="videoFrame"
                        source={moments.images[0].url}
                      />
                    </div>
                  ))}

                <div className="title">
                  <Link
                    to={`/read/${
                      moments?.life?.verified_name || moments?.life?.life_id
                    }/${moments._id}`}
                    target="_blank"
                  >
                    <div className="p">
                      <div className="life-logo-name">
                        <img
                          src={moments.life?.life_logo}
                          className="img"
                        ></img>
                        {index === 0 && moments.images.length < 1 ? (
                          <div
                            className="moment-date"
                            style={{ color: "black" }}
                          >
                            {moments.moment_date}
                          </div>
                        ) : (
                          <div className="moment-date">
                            {moments.moment_date}
                          </div>
                        )}
                      </div>
                      {moments.images.length < 1 ? (
                        <div className="large-des">
                          {moments.moment_title.length > 50
                            ? moments.moment_title.slice(0, 50) + "..."
                            : moments.moment_title}
                        </div>
                      ) : (
                        <div className="moment-title">
                          {moments.moment_title.length > 60
                            ? moments.moment_title.slice(0, 60) + "..."
                            : moments.moment_title}
                        </div>
                      )}
                    </div>
                    <div>
                      {moments.images.length < 1 && index === 0 ? (
                        <div className="para p-0">
                          {moments.moment_details.length > maxLength
                            ? moments.moment_details.slice(0, maxLength) + "..."
                            : moments.moment_details}
                        </div>
                      ) : (
                        moments.images.length < 1 && (
                          <div className="para p-0">
                            {moments.moment_details.length > maxLength
                              ? moments.moment_details.slice(0, maxLength) +
                                "..."
                              : moments.moment_details}
                          </div>
                        )
                      )}
                    </div>
                  </Link>

                  <div
                    className="reaction-icons-large"
                    style={{
                      color:
                        index === 0 && moments.images.length < 1
                          ? "#A8A8A8"
                          : "white",
                    }}
                  >
                    <OverlayTrigger
                      key="left"
                      placement="left"
                      overlay={<Tooltip>Like</Tooltip>}
                    >
                      <div
                        className="mt-2"
                        onClick={() => handleShow(moments._id)}
                      >
                        <i class="bi bi-hand-thumbs-up"></i>
                      </div>
                    </OverlayTrigger>
                    {moments.commentable === true && (
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Comment</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShow(moments._id)}
                        >
                          <i class="bi bi-chat-text"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                    {moments.shareable === true && (
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Share</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShareClick(moments._id)}
                        >
                          <i class="bi bi-send-fill"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>

                {moments.images.length < 1 && index === 0 && (
                  <div className="tile-footer">
                    <div className="reaction-icons d-flex gap-4">
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={<Tooltip>Like</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleCommentModal(moments._id)}
                        >
                          <i class="bi bi-hand-thumbs-up"></i>
                        </div>
                      </OverlayTrigger>
                      {moments.commentable === true && (
                        <OverlayTrigger
                          key="left"
                          placement="left"
                          overlay={<Tooltip>Comment</Tooltip>}
                        >
                          <div
                            className="mt-2"
                            onClick={() => handleCommentModal(moments._id)}
                          >
                            <i class="bi bi-chat-text"></i>
                          </div>
                        </OverlayTrigger>
                      )}
                      {moments.shareable === true && (
                        <OverlayTrigger
                          key="left"
                          placement="left"
                          overlay={<Tooltip>Share</Tooltip>}
                        >
                          <div
                            className="mt-2"
                            onClick={() => handleShareClick(moments._id)}
                          >
                            <i class="bi bi-send-fill"></i>
                          </div>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                )}
                <div className="tile-footer">
                  <div className="reaction-icons d-flex gap-4">
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={<Tooltip>Like</Tooltip>}
                    >
                      <div
                        className="mt-2"
                        onClick={() => handleCommentModal(moments._id)}
                      >
                        <i class="bi bi-hand-thumbs-up"></i>
                      </div>
                    </OverlayTrigger>
                    {moments.commentable === true && (
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip>Comment</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleCommentModal(moments._id)}
                        >
                          <i class="bi bi-chat-text"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                    {moments.shareable === true && (
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip>Share</Tooltip>}
                      >
                        <div
                          className="mt-2"
                          onClick={() => handleShareClick(moments._id)}
                        >
                          <i class="bi bi-send-fill"></i>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              </div>
            )
      );
    }
    return postArray;
  };

  const handleCloseImpressionSet = () => {
    setShowImpressionSet(false);
  };

  const getCountByType = (type, impressionSet) => {
    return impressionSet.filter(
      (impression) => impression.impression_type === type
    ).length;
  };

  const ImpressionSet = useSelector(
    (state) => state.ImpressionSet.impressionSetApi
  );

  return (
    <div>
      <div
        id="scrollerDiv"
        className="dummy-scroll"
        style={{
          height: "100vh",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        tabIndex={0}
      >
        <InfiniteScroll
          pageStart={1}
          loadMore={loadItems}
          hasMore={!isLimitReached}
          loader={
            selectedMoments.length >= limit && !isLimitReached ? (
              <p
                className="loading-word-landing"
                style={{ marginLeft: "20px" }}
              >
                Loading...
              </p>
            ) : null
          }
          useWindow={false}
          initialLoad={false}
        >
          <LifeNavbar />
          <LifeHeader />
          <SearchPage />

          {userLogin && (
            <ReportLifeMoment
              title="Report Moment"
              subtitle="Why do you want to report this moment?"
              lifeMomentId={reportMomentId}
              show={showReportMoment}
              setShow={(bool) => setShowReportMoment(bool)}
            ></ReportLifeMoment>
          )}
          <PopUpModal
            title={message}
            show={popupModalShow}
            setPopupModalShow={(bool) => setPopupModalShow(bool)}
            isMomentDelete={isMomentDelete}
          ></PopUpModal>

          {/* Pop up */}
          <Modal
            className="popup-modal"
            show={showPopup}
            onHide={handleClose}
            centered
          >
            <div className="popup-header">
              <div className="close-icon" onClick={handleClose}>
                <img src={images.closeIcon}></img>
              </div>
              <div className="logo-icon">
                <img src={images.MoofIcon} />
              </div>
            </div>

            <Modal.Body className="modal-body">
              Please Login / Sign-up to comment or like this moment <br></br>
              <Link to="/login">
                <button className="yellow-btn">Login</button>
              </Link>
              <Link to="/sign-up">
                <button className="yellow-btn">Sign Up </button>
              </Link>
            </Modal.Body>
          </Modal>

          <ConfirmationPopup
            title={popupMessages.title}
            subtitle1={popupMessages.subtitle1}
            subtitle2={popupMessages.subtitle2}
            button1={popupMessages.button1}
            button2={popupMessages.button2}
            confirmationPopupShow={confirmationPopupShow}
            setConfirmationPopupShow={(bool) => setConfirmationPopupShow(bool)}
            handleDeleteMoment={handleDeleteMoment}
            momentIndex={momentIndex}
            isMomentDelete={isMomentDelete}
            setIsMomentDelete={setIsMomentDelete}
            setPopupMessages={setPopupMessages}
          ></ConfirmationPopup>

          {/* Link Moment Modal */}
          <Modal
            className="link-moment-modal"
            show={showLinkMomentPopup}
            centered
            backdrop="static"
          >
            <Modal.Header className="title">
              Connect Moment to My Life
            </Modal.Header>
            <div className="modal-body">
              {livesForUser &&
                livesForUser.length > 0 &&
                livesForUser.map((life) => {
                  return (
                    <div
                      className="card"
                      onClick={() => selectLifeForLink(life.life_id)}
                      style={{
                        border:
                          selectedLifeId === life.life_id
                            ? "2px solid #FFC000"
                            : "",
                      }}
                    >
                      <div className="life-set">
                        <div className="d-flex gap-3 align-items-center">
                          <Zoom>
                            <img
                              src={
                                life.life_logo
                                  ? life.life_logo
                                  : images.UserImage
                              }
                              className="create-dropdown-img"
                            ></img>
                          </Zoom>

                          <div>
                            <div className="life-name">{life.life_name}</div>
                            <div className="life-tag">
                              {life.life_title ? life.life_title : ""}
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <Modal.Footer>
              <div className="btn-set">
                <button
                  className="yellow-btn"
                  onClick={handleLinkMoment}
                  disabled={loadingLink || !selectedLifeId}
                >
                  {loadingLink ? (
                    <Spinner
                      animation="border"
                      variant="light"
                      style={{
                        width: "15px",
                        height: "15px",
                      }}
                    />
                  ) : (
                    "Connect"
                  )}
                </button>
                <button
                  className="yellow-btn"
                  onClick={handleLinkMomentPopupClose}
                  disabled={loadingLink}
                >
                  Cancel
                </button>
              </div>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showCommentModal}
            onHide={handleCloseCommentModal}
            centered
            className="comment-moment-modal"
            size="lg"
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedCommentMoment && (
                <div className="moments-container">
                  <Card className="card">
                    <Card.Body>
                      <Row className="life">
                        <Col className="life-logo" sm={1}>
                          <Link>
                            <img
                              className="life-logo"
                              src={selectedCommentMoment.life.life_logo}
                            ></img>
                          </Link>
                        </Col>
                        <Col className="life-content">
                          <h6 className="title-1">
                            <Link>
                              {selectedCommentMoment.life.life_name}
                              <span>
                                <img
                                  className="verified-icon"
                                  src={images.VerifiedIcon}
                                ></img>
                              </span>
                            </Link>
                          </h6>
                          <h6 className="title-2">
                            {selectedCommentMoment.moment_date}{" "}
                            {selectedCommentMoment.era &&
                            selectedCommentMoment.era === "BCE"
                              ? "BCE"
                              : ""}
                          </h6>
                        </Col>
                        <Col className="moment-category">
                          {selectedCommentMoment.world_moment === true ? (
                            <span
                              className="category-word"
                              style={{ background: "#FFF1C6" }}
                            >
                              World Moment
                            </span>
                          ) : selectedCommentMoment.national_moment.length >
                            0 ? (
                            <span
                              className="category-word"
                              style={{ background: "#D6E4FF" }}
                            >
                              National Moment
                            </span>
                          ) : selectedCommentMoment.top_moment === true ? (
                            <span
                              className="category-word"
                              style={{ background: "#D6E4FF" }}
                            >
                              Top Moment
                            </span>
                          ) : selectedCommentMoment.sustainability_moment ===
                            true ? (
                            <span
                              className="category-word"
                              style={{ background: "#DBFFDE" }}
                            >
                              Sustainability
                            </span>
                          ) : null}
                          {selectedCommentMoment.world_moment === true ? (
                            <img src={images.GlobalMoment} alt="World Moment" />
                          ) : selectedCommentMoment.national_moment.length >
                            0 ? (
                            selectedCommentMoment.national_moment
                              .slice(0, 5)
                              .map((countryName, index) => {
                                const countryCode = countryList.find(
                                  (country) => country.country === countryName
                                );

                                if (countryCode) {
                                  return (
                                    <Flag key={index} code={countryCode.code} />
                                  );
                                }
                              })
                          ) : (
                            selectedCommentMoment.top_moment === true && (
                              <img src={images.TopMoment} alt="Top Moment" />
                            )
                          )}
                          {selectedCommentMoment.sustainability_moment ===
                            true && (
                            <img
                              src={images.SustainabilityMoment}
                              alt="Sustainability Moment"
                            />
                          )}
                        </Col>
                      </Row>
                      <Row className="moment">
                        <Col className="moment-logo" sm={1}>
                          {userLogin ? (
                            <Dropdown>
                              <Dropdown.Toggle id="dropdown-basic">
                                <img src={images.MoofIcon}></img>
                              </Dropdown.Toggle>
                            </Dropdown>
                          ) : (
                            <img src={images.MoofIcon}></img>
                          )}
                        </Col>
                        <Col className="moment-title">
                          <Row>
                            <Col>
                              <Link
                                to={`${currentUrl}/${
                                  selectedCommentMoment.life.verified_name
                                    ? selectedCommentMoment.life.verified_name
                                    : selectedCommentMoment.life.life_id
                                }/${selectedCommentMoment._id}`}
                              >
                                <h4 className="title-1">
                                  {selectedCommentMoment.moment_title}
                                </h4>
                              </Link>
                            </Col>
                            <Col sm={1} style={{ textAlign: "right" }}>
                              {selectedCommentMoment.userImpression && (
                                <div>
                                  <img
                                    src={getEmojiAsset(
                                      selectedCommentMoment.userImpression
                                    )}
                                    width="20px"
                                  />
                                </div>
                              )}
                            </Col>
                          </Row>
                          <div className="title-2">
                            {selectedCommentMoment.moment_type.map(
                              (type, index) => (
                                <>| {type} </>
                              )
                            )}
                            {selectedCommentMoment.moment_type}
                            {selectedCommentMoment.moment_type.length > 0 && (
                              <span>|</span>
                            )}
                          </div>
                          <h4 className="title-2"></h4>
                        </Col>
                      </Row>
                      <Card.Text>
                        <div class="moment-content">
                          <p>
                            para
                            {showMore &&
                            currentMoment === selectedCommentMoment._id
                              ? selectedCommentMoment.moment_details
                              : selectedCommentMoment.moment_details.slice(
                                  0,
                                  1500
                                )}
                            {selectedCommentMoment.link_descriptions}
                            {selectedCommentMoment.moment_details.length >
                              1500 &&
                              (showMore &&
                              currentMoment === selectedCommentMoment._id ? (
                                <span
                                  className="seemore"
                                  onClick={() =>
                                    toggleShow(
                                      selectedCommentMoment._id,
                                      "less"
                                    )
                                  }
                                >
                                  see less...
                                </span>
                              ) : (
                                <span
                                  className="seemore"
                                  onClick={() =>
                                    toggleShow(
                                      selectedCommentMoment._id,
                                      "more"
                                    )
                                  }
                                >
                                  see more...
                                </span>
                              ))}
                          </p>
                          <p className="link-hashtag">
                            {selectedCommentMoment.external_link_title && (
                              <>External Reference :&nbsp;</>
                            )}
                            <a
                              target="_blank"
                              href={selectedCommentMoment.external_link}
                            >
                              {selectedCommentMoment.external_link_title}
                            </a>
                            {selectedCommentMoment.hashtags.map((hashtag) => (
                              <span>#{hashtag}</span>
                            ))}
                          </p>

                          <div
                            className="image-gallery"
                            style={{
                              display: "grid",
                              gridTemplateColumns:
                                selectedCommentMoment.images.length === 1
                                  ? "1fr"
                                  : selectedCommentMoment.images.length === 3
                                  ? "repeat(2, 1fr)"
                                  : "repeat(2, 1fr)",
                              gridAutoRows: "auto",
                              height:
                                selectedCommentMoment.images &&
                                (selectedCommentMoment.images.length === 2 ||
                                  selectedCommentMoment.images.length === 3)
                                  ? "200px"
                                  : selectedCommentMoment.images &&
                                    selectedCommentMoment.images.length === 1
                                  ? "500px"
                                  : "400px",
                            }}
                          >
                            {selectedCommentMoment.images.length === 3
                              ? selectedCommentMoment.images
                                  .slice(0, 2)
                                  .map((image, imageIndex) => (
                                    <div
                                      key={imageIndex}
                                      className={`image-wrapper${
                                        imageIndex === 0 ? " full-width" : ""
                                      }`}
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        margin: "auto",
                                      }}
                                    >
                                      {image.media_type === "image" ||
                                      !image.media_type ? (
                                        <img
                                          src={image.url}
                                          alt={`Image ${imageIndex + 1}`}
                                          style={{
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                          }}
                                        />
                                      ) : (
                                        image.media_type === "video" && (
                                          <VideoPlayer source={image.url} />
                                        )
                                      )}
                                      {imageIndex === 1 &&
                                        selectedCommentMoment.images.length >
                                          2 &&
                                        !showAllImages && (
                                          <div className="more-indicator">
                                            <i class="bi bi-image"></i>
                                          </div>
                                        )}
                                    </div>
                                  ))
                              : selectedCommentMoment.images
                                  .slice(0, 4)
                                  .map((image, imageIndex) => (
                                    <div
                                      key={imageIndex}
                                      className={`image-wrapper${
                                        imageIndex === 0 ? " full-width" : ""
                                      }`}
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        margin: "auto",
                                      }}
                                    >
                                      {image.media_type === "image" ||
                                      !image.media_type ? (
                                        <img
                                          src={image.url}
                                          alt={`Image ${imageIndex + 1}`}
                                          style={{
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                          }}
                                        />
                                      ) : (
                                        image.media_type === "video" && (
                                          <VideoPlayer source={image.url} />
                                        )
                                      )}
                                      {imageIndex === 3 &&
                                        selectedCommentMoment.images.length >
                                          4 &&
                                        !showAllImages && (
                                          <div className="more-indicator">
                                            +{" "}
                                            {selectedCommentMoment.images
                                              .length - 4}
                                          </div>
                                        )}
                                    </div>
                                  ))}
                          </div>
                        </div>
                      </Card.Text>
                      <hr></hr>
                      <Row className="icons-row">
                        <Col>
                          <div>
                            <div></div>
                          </div>
                          <div className="icons d-flex">
                            {selectedCommentMoment.reaction_counts > 0 && (
                              <div>
                                {totalFeedbacks(
                                  selectedCommentMoment.reaction_counts
                                )}
                              </div>
                            )}

                            <div class="feed" onClick={handleImpressionClick}>
                              <div style={{ display: "flex !important" }}>
                                {totalFeedbacks(
                                  selectedCommentMoment.reaction_counts
                                ) === 0 ? (
                                  <div>
                                    <img
                                      src={images.ImpressionsIcon}
                                      alt="No Feedback"
                                      width="20"
                                      height="20"
                                      style={{
                                        marginLeft: "10px",
                                        marginTop: "-10px",
                                      }}
                                    />{" "}
                                  </div>
                                ) : (
                                  <div
                                    className="top3-emojis-container"
                                    style={{
                                      marginLeft: "5px",
                                    }}
                                  >
                                    {getTopReactions(
                                      selectedCommentMoment.reaction_counts
                                    ).map((emoji) => (
                                      <div key={emoji}>
                                        <img
                                          src={getEmojiAsset(emoji)}
                                          alt={emoji}
                                          width="20"
                                          height="20"
                                        />
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                              <a class="like-btn">
                                {userLogin && impressionBoxVisible && (
                                  <div class="reaction-box" ref={shareCommentBoxRef}>
                                    {(!selectedCommentMoment.impression_types ||
                                      selectedCommentMoment.impression_types
                                        .like === true) && (
                                      <div
                                        class="reaction-icon"
                                        onClick={() =>
                                          handleEmojiClick(
                                            "like",
                                            selectedCommentMoment._id
                                          )
                                        }
                                      >
                                        <label>Like this Moment</label>
                                        <img src={images.LikeIcon}></img>
                                      </div>
                                    )}

                                    {(!selectedCommentMoment.impression_types ||
                                      selectedCommentMoment.impression_types
                                        .great === true) && (
                                      <div
                                        class="reaction-icon"
                                        onClick={() =>
                                          handleEmojiClick(
                                            "great",
                                            selectedCommentMoment._id
                                          )
                                        }
                                      >
                                        <label>Great Moment</label>
                                        <img src={images.GreatIcon}></img>
                                      </div>
                                    )}

                                    {(!selectedCommentMoment.impression_types ||
                                      selectedCommentMoment.impression_types
                                        .emotional === true) && (
                                      <div
                                        class="reaction-icon"
                                        onClick={() =>
                                          handleEmojiClick(
                                            "emotional",
                                            selectedCommentMoment._id
                                          )
                                        }
                                      >
                                        <label>Emotional Moment</label>
                                        <img src={images.EmotionalIcon}></img>
                                      </div>
                                    )}

                                    {(!selectedCommentMoment.impression_types ||
                                      selectedCommentMoment.impression_types
                                        .nostalgic === true) && (
                                      <div
                                        class="reaction-icon"
                                        onClick={() =>
                                          handleEmojiClick(
                                            "nostalgic",
                                            selectedCommentMoment._id
                                          )
                                        }
                                      >
                                        <label>Nostalgic Moment</label>
                                        <img src={images.NostalgicIcon}></img>
                                      </div>
                                    )}

                                    {(!selectedCommentMoment.impression_types ||
                                      selectedCommentMoment.impression_types
                                        .wish === true) && (
                                      <div
                                        class="reaction-icon"
                                        onClick={() =>
                                          handleEmojiClick(
                                            "wish",
                                            selectedCommentMoment._id
                                          )
                                        }
                                      >
                                        <label>Wish I could be there</label>
                                        <img src={images.WishIcon}></img>
                                      </div>
                                    )}

                                    {(!selectedCommentMoment.impression_types ||
                                      selectedCommentMoment.impression_types
                                        .part === true) && (
                                      <div
                                        class="reaction-icon"
                                        onClick={() =>
                                          handleEmojiClick(
                                            "part",
                                            selectedCommentMoment._id
                                          )
                                        }
                                      >
                                        <label>Was part of this Moment</label>
                                        <img src={images.PartOfTheMomentIcon} />
                                      </div>
                                    )}

                                    {(!selectedCommentMoment.impression_types ||
                                      selectedCommentMoment.impression_types
                                        .witnessed === true) && (
                                      <div
                                        class="reaction-icon"
                                        onClick={() =>
                                          handleEmojiClick(
                                            "witnessed",
                                            selectedCommentMoment._id
                                          )
                                        }
                                      >
                                        <label>Witnessed this Moment</label>
                                        <img src={images.WitnessedIcon}></img>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </a>
                            </div>
                            {/* <img className="icons-image" src={impressions}></img> */}
                            <p
                              className="impression-word"
                              onClick={() =>
                                handleImpressionSet(selectedCommentMoment._id)
                              }
                              style={{
                                marginLeft:
                                  totalFeedbacks(
                                    selectedCommentMoment.reaction_counts
                                  ) > 0
                                    ? "0px"
                                    : "10px",
                                cursor:"pointer"
                              }}
                            >
                              Like
                            </p>
                          </div>
                        </Col>
                        {selectedCommentMoment.commentable === true && (
                          <Col>
                            <div
                              className="icons comment-icon d-flex gap-3"
                              onClick={() =>
                                handleCommentOpen(selectedCommentMoment._id)
                              }
                            >
                              {selectedCommentMoment.number_of_comments > 0 && (
                                <div>
                                  {selectedCommentMoment.number_of_comments}
                                </div>
                              )}
                              <img
                                className="icons-image"
                                src={images.CommentIcon}
                                style={{ marginTop: "2px" }}
                              ></img>
                              <p className="comments-word">Comment</p>
                            </div>
                          </Col>
                        )}
                        {selectedCommentMoment.shareable === true && (
                          <Col style={{ textAlign: "right" }}>
                            {showTooltip === true && (
                              <div className="copy-message">Link copied</div>
                            )}
                            <div className="icons share-icon d-flex gap-3 justify-content-end">
                              <div class="share-feed">
                                <a
                                  class="share-btn"
                                  onClick={() =>
                                    handleCommentModalShareClick(
                                      selectedCommentMoment._id
                                    )
                                  }
                                >
                                  <img
                                    className="icons-image"
                                    src={images.ShareIcon}
                                  ></img>
                                  {commentShareBoxVisible &&
                                    shareMoment ===
                                      selectedCommentMoment._id && (
                                      <div
                                        class="share-box"
                                        ref={shareCommentBoxRef}
                                      >
                                        <div class="share-icon">
                                          <label>Telegram</label>
                                          <TelegramShareButton
                                            url={`${currentUrl}/${
                                              selectedCommentMoment.life
                                                .verified_name
                                                ? selectedCommentMoment.life
                                                    .verified_name
                                                : selectedCommentMoment.life
                                                    .life_id
                                            }/${selectedCommentMoment._id}`}
                                            title={
                                              selectedCommentMoment.moment_title
                                            }
                                          >
                                            <TelegramIcon
                                              size={32}
                                              round
                                              className="icon"
                                            />
                                          </TelegramShareButton>
                                        </div>
                                        <div class="share-icon">
                                          <label>Linkedin</label>
                                          <LinkedinShareButton
                                            url={`${currentUrl}/${
                                              selectedCommentMoment.life
                                                .verified_name
                                                ? selectedCommentMoment.life
                                                    .verified_name
                                                : selectedCommentMoment.life
                                                    .life_id
                                            }/${selectedCommentMoment._id}`}
                                            title={
                                              selectedCommentMoment.moment_title
                                            }
                                            source="MoofLife"
                                          >
                                            <LinkedinIcon
                                              size={32}
                                              round
                                              className="icon"
                                            />
                                          </LinkedinShareButton>
                                        </div>
                                        <div class="share-icon">
                                          <label>Whatsapp</label>
                                          <WhatsappShareButton
                                            url={`${currentUrl}/${
                                              selectedCommentMoment.life
                                                .verified_name
                                                ? selectedCommentMoment.life
                                                    .verified_name
                                                : selectedCommentMoment.life
                                                    .life_id
                                            }/${selectedCommentMoment._id}`}
                                            title={
                                              selectedCommentMoment.moment_title
                                            }
                                            separator=":: "
                                          >
                                            <WhatsappIcon
                                              size={32}
                                              round
                                              className="icon"
                                            />
                                          </WhatsappShareButton>
                                        </div>
                                        <div class="share-icon">
                                          <label>Facebook</label>
                                          <FacebookShareButton
                                            url={`${currentUrl}/${
                                              selectedCommentMoment.life
                                                .verified_name
                                                ? selectedCommentMoment.life
                                                    .verified_name
                                                : selectedCommentMoment.life
                                                    .life_id
                                            }/${selectedCommentMoment._id}`}
                                          >
                                            <FacebookIcon
                                              size={32}
                                              round
                                              className="icon"
                                            />
                                          </FacebookShareButton>
                                        </div>
                                        <div class="share-icon">
                                          <label>Mask</label>
                                          <TwitterShareButton
                                            url={`${currentUrl}/${
                                              selectedCommentMoment.life
                                                .verified_name
                                                ? selectedCommentMoment.life
                                                    .verified_name
                                                : selectedCommentMoment.life
                                                    .life_id
                                            }/${selectedCommentMoment._id}`}
                                            title={
                                              selectedCommentMoment.moment_title
                                            }
                                          >
                                            <XIcon
                                              size={32}
                                              round
                                              className="icon"
                                            />
                                          </TwitterShareButton>
                                        </div>
                                        <div class="share-icon icon-copy">
                                          <div class="hover-text">
                                            {/* <!-- Copy Text --> */}
                                            <div className="copy-text d-flex">
                                              <input
                                                ref={commentTextRef}
                                                value={`${currentUrl}/${
                                                  selectedCommentMoment.life
                                                    .verified_name
                                                    ? selectedCommentMoment.life
                                                        .verified_name
                                                    : selectedCommentMoment.life
                                                        .life_id
                                                }/${selectedCommentMoment._id}`}
                                                disabled
                                                type="text"
                                              />
                                            </div>
                                          </div>
                                          <img
                                            src={images.CopyIcon}
                                            onClick={() =>
                                              copyToCommentClipboard(
                                                selectedCommentMoment._id,
                                                selectedCommentMoment
                                              )
                                            }
                                            className="icon"
                                          ></img>
                                        </div>
                                      </div>
                                    )}
                                </a>
                              </div>
                              <p
                                className="share-word"
                                onClick={() =>
                                  handleShareClick(selectedCommentMoment._id)
                                }
                              >
                                Share
                              </p>
                            </div>
                          </Col>
                        )}
                      </Row>
                      {userLogin
                        ? commentOpen &&
                          commentMoment === selectedCommentMoment._id && (
                            <Comments
                              currentUserId={
                                userData && userData.id ? userData.id : null
                              }
                              momentId={selectedCommentMoment._id}
                              area="landingPage"
                            ></Comments>
                          )
                        : commentOpen &&
                          commentMoment === selectedCommentMoment._id && (
                            <Comments
                              momentId={selectedCommentMoment._id}
                            ></Comments>
                          )}
                    </Card.Body>
                  </Card>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          {/* Image Modal */}
          <Modal
            id="imageshw"
            size="lg"
            className="modal-image"
            show={showModal}
            onHide={handleCloseModal}
            centered
            style={{ maxWidth: "100%", margin: "0 auto" }}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div>
                <div className="title-1">{selectedImage.title}</div>
                {selectedImage &&
                  (selectedImage.media_type === "image" ||
                  !selectedImage.media_type ? (
                    <div className="image-div">
                      <Zoom>
                        <img
                          className="modal-moment-img"
                          src={selectedImage.url}
                        />
                      </Zoom>
                    </div>
                  ) : (
                    selectedImage.media_type === "video" && (
                      <VideoPlayer source={selectedImage.url} />
                    )
                  ))}

                <Row>
                  <Row>
                    {selectedImage.source && (
                      <p className="title-2">
                        Source : <span>{selectedImage.source}</span>
                      </p>
                    )}
                  </Row>
                  <Row>
                    {selectedImage.credit && (
                      <p className="title-2">
                        Credit : <span>{selectedImage.credit}</span>
                      </p>
                    )}
                  </Row>
                  <Row className="title-2">
                    <div className="image-copyright">
                      This content may be subject to copyright.
                      <span className="image-copyright-link">
                        Refer privacy policy
                      </span>
                    </div>
                  </Row>
                </Row>
              </div>
            </Modal.Body>
          </Modal>

               {/* Impressions Set */}
               <Modal
                  size="lg"
                  show={showImpressionSet}
                  onHide={handleCloseImpressionSet}
                  backdrop="static"
                  keyboard={false}
                  className="impressions-set-main"
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="title">Impressions</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Tabs
                      defaultActiveKey="All"
                      id="uncontrolled-tab-example"
                      className="mb-3 tabs"
                    >
                      {[
                        { emoji_type: "All", title: "All" },
                        {
                          emoji_type: "part",
                          title: "angry",
                          icon: images.PartOfTheMomentIcon,
                        },
                        {
                          emoji_type: "like",
                          title: "like",
                          icon: images.LikeIcon,
                        },
                        {
                          emoji_type: "great",
                          title: "great",
                          icon: images.GreatIcon,
                        },
                        {
                          emoji_type: "emotional",
                          title: "emotional",
                          icon: images.EmotionalIcon,
                        },
                        {
                          emoji_type: "witnessed",
                          title: "witnessed",
                          icon: images.WitnessedIcon,
                        },
                        {
                          emoji_type: "nostalgic",
                          title: "nostalgic",
                          icon: images.NostalgicIcon,
                        },
                        {
                          emoji_type: "wish",
                          title: "wish",
                          icon: images.WishIcon,
                        },
                      ]
                        .filter((tab) => {
                          if (tab.emoji_type === "All") {
                            return true; // Always show the "All" tab
                          } else {
                            const count = getCountByType(
                              tab.emoji_type,
                              ImpressionSet
                            );
                            return count > 0; // Show the tab only if the count is greater than 0
                          }
                        })
                        .map((tab, index) => (
                          <Tab
                            key={index}
                            eventKey={tab.emoji_type}
                            title={
                              <>
                                {tab.emoji_type === "All" ? (
                                  "All"
                                ) : (
                                  <img
                                    src={tab.icon}
                                    width="20px"
                                    style={{ padding: "0px" }}
                                    alt={`${tab.emoji_type} tab icon`}
                                  ></img>
                                )}

                                <span>
                                  {tab.emoji_type === "All"
                                    ? ImpressionSet.length
                                    : getCountByType(
                                        tab.emoji_type,
                                        ImpressionSet
                                      )}
                                </span>
                              </>
                            }
                          >
                            {tab.emoji_type === "All"
                              ? ImpressionSet.map((impressionSet, index) => (
                                  <div className="impression-row" key={index}>
                                    <div>
                                      {impressionSet.user?.image_url ? (
                                        <div>
                                          <img
                                            src={impressionSet.user?.image_url}
                                            className="profile-icon"
                                          ></img>
                                        </div>
                                      ) : (
                                        <div
                                          className="user-image-letter"
                                          id="name"
                                          style={{
                                            background:
                                              impressionSet.user
                                                ?.theme_colour || "#D9D9D9",
                                          }}
                                        >
                                          {impressionSet.user?.first_name?.charAt(
                                            0
                                          )}
                                          {impressionSet.user &&
                                          impressionSet.user.last_name
                                            ? impressionSet.user.last_name.charAt(
                                                0
                                              )
                                            : ""}
                                        </div>
                                      )}
                                      <img
                                        className="emoji-icon"
                                        src={getEmojiAsset(
                                          impressionSet?.impression_type
                                        )}
                                        alt={`${impressionSet?.impression_type} emoji`}
                                      ></img>
                                    </div>
                                    <div className="profile-name">
                                      {`${impressionSet.user?.first_name} ${
                                        impressionSet.user?.last_name
                                          ? impressionSet.user?.last_name
                                          : ""
                                      }`}
                                    </div>
                                  </div>
                                ))
                              : ImpressionSet.filter(
                                  (impression) =>
                                    impression.impression_type ===
                                    tab.emoji_type
                                ).map((impressionSet, index) => (
                                  <div className="impression-row" key={index}>
                                    <div>
                                      {impressionSet.user === "" ? (
                                        <img
                                          className="user-image"
                                          src={images.UserImage}
                                        ></img>
                                      ) : (
                                        <>
                                          {impressionSet.user?.image_url ? (
                                            <div>
                                              <img
                                                src={
                                                  impressionSet.user?.image_url
                                                }
                                                className="profile-icon"
                                              ></img>
                                            </div>
                                          ) : (
                                            <div
                                              className="user-image-letter"
                                              id="name"
                                              style={{
                                                background:
                                                  impressionSet.user
                                                    ?.theme_colour || "#D9D9D9",
                                              }}
                                            >
                                              {impressionSet.user?.first_name?.charAt(
                                                0
                                              )}

                                              {impressionSet.user &&
                                              impressionSet.user.last_name
                                                ? impressionSet.user.last_name.charAt(
                                                    0
                                                  )
                                                : ""}
                                            </div>
                                          )}
                                        </>
                                      )}
                                      <img
                                        className="emoji-icon"
                                        src={getEmojiAsset(
                                          impressionSet?.impression_type
                                        )}
                                        alt={`${impressionSet?.impression_type} emoji`}
                                      ></img>
                                    </div>
                                    <div className="profile-name">
                                      {`${impressionSet.user?.first_name} ${
                                        impressionSet.user?.last_name
                                          ? impressionSet.user.last_name
                                          : ""
                                      }`}
                                    </div>
                                  </div>
                                ))}
                          </Tab>
                        ))}
                    </Tabs>
                  </Modal.Body>
                </Modal>

          <div className="life-moments-main">
            <div className="tile-view-main">
              {Object.keys(momentsByYear)
                .sort((a, b) => momentSorting === -1 ? b - a : a - b)
                .map((year) => (
                  <>
                    <div className="year">{year}</div>
                    <div
                      className={`grid ${
                        momentsByYear[year].length === 1 ? "one-grid" : ""
                      }`}
                      style={{
                        gridTemplateColumns:
                          momentsByYear[year].length === 1
                            ? "repeat(1, 1fr)"
                            : "",
                      }}
                    >
                      {renderLayout(momentsByYear[year])}
                    </div>
                  </>
                ))}
            </div>
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default LifeMoments;
