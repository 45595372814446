import React, { useEffect } from "react";
import "../../styles/LandingPage/NewLivesStyle.css";
import Slider from "react-slick";
import { fetchNewLives } from "../../store/action/LifeAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const NewLives = () => {
  const dispatch = useDispatch();
  const NewLivesList = useSelector((state) => state.Life.NewLives);

  useEffect(() => {
    dispatch(fetchNewLives());
  }, [dispatch]);

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      ></div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      ></div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 0,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: (
      <button onClick={SamplePrevArrow}>
        <i
          class="bi bi-chevron-double-left"
          style={{ color: "grey", fontSize: "16px" }}
        ></i>
      </button>
    ),

    nextArrow: (
      <button onClick={SampleNextArrow}>
        <i
          class="bi bi-chevron-double-right"
          style={{ color: "grey", fontSize: "16px" }}
        ></i>
      </button>
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 578,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="new-lives-main">
      <div className="new-lives-title">
        New <span>Lives</span>
      </div>
      <Slider {...settings}>
        {NewLivesList.map((newLives, index) => (
          <div key={index}>
            {index % 2 !== 0 ? (
              <div className="content">
                <Link
                  to={`/${
                    newLives.verified_name
                      ? newLives.verified_name
                      : newLives.life_id
                  }`}
                >
                  <img className="image" src={newLives.cover_image} />
                  <img
                    className="image-logo"
                    src={newLives.life_logo}
                    style={{
                      border: newLives.life_logo ? "2px solid #FFC000" : "none",
                    }}
                  />
                  <div className="details" style={{ marginTop: "20px" }}>
                    <div className="title">
                      {newLives.life_name.length > 15
                        ? newLives.life_name.slice(0, 15) + "..."
                        : newLives.life_name}
                    </div>
                  </div>
                </Link>
              </div>
            ) : (
              <div className="content">
                <Link
                  to={`/${
                    newLives.verified_name
                      ? newLives.verified_name
                      : newLives.life_id
                  }`}
                >
                  <div className="details" style={{ marginBottom: "20px" }}>
                    <div className="title">
                      {newLives.life_name.length > 15
                        ? newLives.life_name.slice(0, 15) + "..."
                        : newLives.life_name}
                    </div>
                  </div>
                  <img className="image" src={newLives.cover_image} />
                  <img
                    className="image-logo"
                    src={newLives.life_logo}
                    style={{
                      border: newLives.life_logo ? "2px solid #FFC000" : "none",
                    }}
                  />
                </Link>
              </div>
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default NewLives;
