import React from "react";
import "../../src/styles/LoginStyle.css";
import "../../src/styles/CommonStyle.css";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import MomentJS from "moment";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  googleAuth,
  facebookAuth,
} from "../store/action/userInteractionsAction";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { images } from "../utils/image";
import {
  userLogin,
  setUserDetails,
  setForgotPasswordDetails,
} from "../store/action/userInteractionsAction";
import {
  setIntoSessionStorage,
  setIntoLocalStorage,
  getFromSessionStorage,
} from "../utils/storageHandler";
const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const LoginPage = () => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const URLState = location.state;
  const [passwordType, setPasswordType] = useState("password");
  const [emailPatternErrorMessage, setEmailPatternErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [rememberMe, setRememberMe] = useState(false);
  const forgotPasswordStatus = {
    forgetPassword: true,
    otp: false,
  };

  useEffect(() => {
    const accessToken = getFromSessionStorage("accessToken");
    if (accessToken) {
      navigate("/");
    }
  }, []);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const onSuccess = (res) => {
    dispatch(googleAuth({ credential: res.credential, remember: rememberMe}))
      .then((res) => {
        if (res && res.success && res.data && res.data.token) {
          const tokenExpireHours = rememberMe ? MomentJS().add(28, "days") : MomentJS().add(10, "hours");
          const tokenExpireAt = tokenExpireHours;

          setIntoLocalStorage("accessToken", res.data.token);
          setIntoLocalStorage("userDetails", res.data.userDetails, true);
          setIntoLocalStorage("tokenExpireTime", tokenExpireAt);
          setIntoLocalStorage("interest", res.data.interest, true);
          setIntoLocalStorage("selected_countries", res.data.selected_countries, true);
          setIntoLocalStorage("register_type", res.data.register_type);
          
          if(URLState && URLState.from && URLState.from === 'user-settings'){
            navigate("/user-settings");
          }else {
            navigate("/");
          }
        }
        if (res && res.success && res.data && res.data.newUser) {
          dispatch(setUserDetails(res.data.newUser))
            .then(() => {
              const rememberMeValue = rememberMe ? "true" : "false";
              navigate(`/terms-and-conditions`, {
                state: { type: "google", rememberMe: rememberMeValue },
              });
            })
            .catch((error) => {
              console.error("Error in setUserDetails:", error);
            });
        }
        if(res && !res.success && res.data && res.data.anotherMethod){
          setErrorMessage(
            "You Sign-in in with another method"
          );
        }
      })
      .catch((error) => {
        console.error("Error in login:", error);
      });
  };

  const onFailure = (res) => {
    console.log("Google LOGIN FAILED !");
  };

  const onSuccessFacebook = (res) => {
    dispatch(
      facebookAuth({
        accessToken: res.accessToken,
        userID: res.userID,
      })
    );
  };

  const onFailureFacebook = (res) => {
    console.log("Facebook LOGIN FAILED !");
  };

  const handleValueChange = (evt) => {
    const { id, value } = evt.target;
    setLoginData((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));

    if (id === "email") {
      if (!emailPattern.test(value)) {
        setEmailPatternErrorMessage("Type valid email address");
      } else {
        setEmailPatternErrorMessage("");
      }
    }
  };

  const handleLogin = () => {
    if (loginData && loginData.email !== "" && loginData.password !== "") {
      const loginDetails = {
        email: loginData.email.toLowerCase(),
        password: loginData.password,
        remember: rememberMe
      };
      setLoading(true);
      dispatch(userLogin(loginDetails))
        .then((res) => {
          if (res && res.success && res.data.token) {
            const tokenExpireHours = rememberMe ? MomentJS().add(28, "days") : MomentJS().add(10, "hours");
            const tokenExpireAt = tokenExpireHours;

            setIntoLocalStorage("accessToken", res.data.token);
            setIntoLocalStorage("userDetails", res.data.userDetails, true);
            setIntoLocalStorage("tokenExpireTime", tokenExpireAt);  
            setIntoLocalStorage("interest", res.data.interest, true);
            setIntoLocalStorage("selected_countries", res.data.selected_countries, true);
            setIntoLocalStorage("register_type", res.data.register_type);

            setLoading(false);
            if(URLState && URLState.from && URLState.from === 'user-settings'){
              navigate("/user-settings");
            }else {
              navigate("/");
            }
            
          }
          if (res && !res.success && res.handle) {
            if (res.sendOtp) {
              dispatch(setForgotPasswordDetails(forgotPasswordStatus))
                .then(() => {
                  setLoading(false);
                  navigate(`/otp-verification`, {
                    state: { email: loginData.email, warning: true },
                  });
                })
                .catch((error) => {
                  console.error("Error in setForgotPasswordDetails:", error);
                });
            } else {
              console.log(res.error); // add notification for this error.it got like this errors(You have one more attempt before being locked out.)
              setLoading(false);
              setErrorMessage(
                "You have one more attempt before being locked out."
              );
            }
          }else if(res && !res.success){
            setLoading(false);
              setErrorMessage(
                "Invalid user credentials"
              );
          }
          if ((res.success = false)) {
            console.log(res.error);
          }
        })
        .catch((error) => {
          console.error("Error in login:");
        });
    } else {
      console.log("All credentials required!");
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleLogin();
      }
    };
  
    document.addEventListener('keypress', handleKeyPress);
  
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [handleLogin]);

  const handleRememberMe = (evt) => {
    const { checked } = evt.target;
    setRememberMe(checked);
  };
  
  return (
    <div>
      <Container className="login-container">
        <Row>
          <Col>
            {" "}
            <div className="login-main">
              <section className="login-logo">
                <Link to="/">
                  <img className="logo" src={images.moofLogo}></img>
                </Link>
              </section>
              <section>
                <form className="login-form">
                  <p className="title-1">Login to your MoofLife account.</p>

                  <div className="google-login">
                    {/* <div className="google-login-title">
                      Sign in with your Google account
                    </div> */}
                    <div>
                      <GoogleOAuthProvider
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                      >
                        <GoogleLogin
                          className="custom-google-login"
                          buttonText="Login"
                          onSuccess={(res) => onSuccess(res)}
                          onFailure={onFailure}
                          cookiePolicy={"single_host_origin"}
                          isSignedIn={true}
                        />
                      </GoogleOAuthProvider>
                    </div>
                  </div>


                  <div className="or-word">
                      Or
                    </div>

                  <div className="form-group">
                    <input
                      class="form-control"
                      type="text"
                      name="email"
                      id="email"
                      value={loginData.email}
                      placeholder="Enter your email"
                      onChange={handleValueChange}
                    />
                    {emailPatternErrorMessage && (
                      <small>
                        <div className="error-message">
                          <span>
                            <i class="bi bi-exclamation-circle"></i>
                          </span>
                          {emailPatternErrorMessage}
                        </div>
                      </small>
                    )}
                  </div>
                  <div className="form-group">
                    <div className="password-input-container">
                      <input
                        className="form-control"
                        type={passwordType}
                        id="password"
                        placeholder="Password"
                        value={loginData.password}
                        name="password"
                        onChange={handleValueChange}
                      />
                      <button
                        type="button"
                        className="toggle-password"
                        onClick={togglePassword}
                      >
                        {passwordType === "password" ? (
                          <img src={images.eyeSlash} width="20px"></img>
                        ) : (
                          <img src={images.eye} width="20px"></img>
                        )}
                      </button>
                    </div>
                  </div>

                  {errorMessage && (
                    <small>
                      <div className="error-message">
                        <span>
                          <i class="bi bi-exclamation-circle"></i>
                        </span>
                        {errorMessage}
                      </div>
                    </small>
                  )}

                  <Row>
                    <Col>
                      {" "}
                      <Form.Group className="mb-3">
                        <Form.Check
                          className="remember-me"
                          label="Keep me logged-in"
                          feedback="You must agree before submitting."
                          feedbackType="invalid"
                          onChange={handleRememberMe}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="forget-password">
                      <Link to="/forget-password">Forget Password ?</Link>
                    </Col>
                  </Row>

                  <Row>
                    <Col></Col>
                  </Row>
                  <button
                    type="button"
                    className="btn-login"
                    onClick={() => handleLogin()}
                    disabled={
                      loginData.email === "" ||
                      loginData.password === "" ||
                      emailPatternErrorMessage
                    }
                  >
                    {loading ? (
                      <Spinner
                        animation="border"
                        variant="light"
                        style={{ width: "15px", height: "15px" }}
                      />
                    ) : (
                      "Login"
                    )}
                  </button>

                  
                </form>

                <p className="title-2">
                  Don't have an account yet ?{" "}
                  <span>
                    <Link to="/sign-up">Sign up</Link>
                  </span>
                </p>
                <p className="title-3">MoofLife © {currentYear}</p>
              </section>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoginPage;
